import { Box, Button, Stack, Typography } from "@mui/material";
import { useInfiniteQuery } from '@tanstack/react-query';
import { Plus } from "phosphor-react";
import { useCallback, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PostCard from "../../../component/PostCard";
import SearchBox from "../../../component/SearchBox";
import { fetchFounderPost } from "../../../services/founderPost.service";

const FounderPost = () => {
    let { founder_id } = useParams();
    const observer = useRef();
    const [search, setSearch] = useState('');
    const query = {
        founder_id,
        limit: 10,
        search
    };

 
    const { data, isLoading, fetchNextPage, hasNextPage, } = useInfiniteQuery({
        queryKey: ['foundersPost', query],
        queryFn: ({ pageParam = 1 }) => fetchFounderPost({ ...query, page: pageParam }),
        getNextPageParam: (lastPage) => {
            return lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined;
        }
    });
    
    const templates = useMemo(() => {
        return data?.pages.reduce((acc, page) => {
            return [...acc, ...page.foundersPost];
        }, []);
    }, [data]);

    const lastTemplateElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNextPage) {
                fetchNextPage();
            }
        });
        if (node) observer.current.observe(node);
    }, [fetchNextPage, hasNextPage]);

    const handleSearchChange = (value) => {
        setSearch(value);
    };
    return (
        <Box>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mt={4} mb={6}>
                <Box>
                    <Typography variant="h5" color={'text.main'} fontWeight={600}>Founder Previous Post</Typography>
                    <Typography variant="body2" color={'text.light'}>Inspiration and Templates to Kickstart Your Entrepreneurial Journey</Typography>
                </Box>
                <Link to={'/founder/add-post'} state={{ founder_id }}>
                    <Button variant="contained" size="large" endIcon={<Plus />}>Add New Post</Button>
                </Link>
            </Stack>
            <Stack>
                <SearchBox onSearchChange={handleSearchChange} isLoading={isLoading} />
            </Stack>
            <Stack gap={2} py={4}>
                {
                    templates?.map((templateItem) => (
                        <PostCard key={templateItem._id} {...templateItem}  hightlight={search}/>
                    ))
                }
                {hasNextPage && (
                    <Stack ref={lastTemplateElementRef} alignItems={'center'} justifyContent={'center'}>
                        <Typography>Loading ...</Typography>
                    </Stack>
                )}
            </Stack>
        </Box>
    )
}

export default FounderPost;