import api from "../utils/api";

export const fetchLinkedInPosts = async ({ page = 1, limit = 10, search = '', maxContentLength, minLikes, minComments, sortBy, userId, startDate, endDate }) => {
    try {
        const response = await api.get('/linkedin/posts', {
            params: { page, limit, search, maxContentLength, minLikes, minComments, sortBy, userId, startDate, endDate }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const fetchLinkedInUsers = async ({ page = 1, limit = 10, search = '' }) => {
    try {
        const response = await api.get('/linkedin/users', {
            params: { page, limit, search, }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const fetchTopics = async ({ page = 1, limit = 10, search = '' }) => {
    try {
        const response = await api.get('/linkedin/hot-topics');
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addTopics = async (body) => {
    try {
        const response = await api.post('/linkedin/add-topics', body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addLinkedInProfile = async (formData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const response = await api.post('/linkedin/create-user', formData, config);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const editLinkedInProfile = async (formData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const response = await api.patch(`/linkedin/edit-user/${formData.profileId}`, formData.formData, config);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const toggleProfileStatus = async ({ profileId }) => {
    try {
        const response = await api.post(`/linkedin/toggle-status/${profileId}`);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const bulkuploadProfile = async (body) => {
    try {
        const response = await api.post('/linkedin/bulkupload-profile', body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
