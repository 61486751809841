import { AppBar, Box, Breadcrumbs, Button, Container, Grid, Stack, styled, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../layout/Header';
import { editReleventProfile } from '../../../services/releventProfile.service';

const StyledGrid = styled(Grid)(({ theme }) => ({
    background: 'white',
    padding: 32,
    borderRadius: 32,
}));

const Label = ({ label, required }) => {
    return (
        <Typography variant='subtitle2' mb={0.5}>
            {label} {required && <span style={{ color: 'tomato' }}>*</span>}
        </Typography>
    );
}

const EditProfile = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { mutate, isPending } = useMutation({
        mutationFn: editReleventProfile,
        onSuccess: () => {
            toast.success('Edit Profile successfully!');
            handleGoBack()
        },
        onError: (err) => {
            toast.error(err.message || 'Failed to edit profile');
        }
    })


    const [formData, setFormData] = useState({
        name: state?.name || '',
        company: state?.company || '',
        linkedin_url: state?.linkedin_url || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formatData = {
            profile_id: state._id,
            body: formData
        }
        mutate(formatData);
    };

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <>
            <Header />
            <Container >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4 }}>
                    <Box style={{ color: '#858585', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleGoBack} >
                        <Typography color="text.light">Back</Typography>
                    </Box>
                    <Typography color="text.main">Add Relevant Profile</Typography>
                </Breadcrumbs>
                <Box mt={3}>
                    <Typography variant="h6" fontWeight={600} color="textPrimary">
                        Edit Profile
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        Provide details to edit a profile in the system.
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <form onSubmit={handleSubmit}>
                        <StyledGrid container mt={4}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    Profile Details
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    All fields are required. Please provide the following information.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box mb={2}>
                                    <Label label={'Name'} required={true} />
                                    <TextField required size='small' fullWidth name="name" variant="outlined" value={formData.name} onChange={handleChange} placeholder='Enter name' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Company'} required={true} />
                                    <TextField required fullWidth size='small' name="company" variant="outlined" value={formData.company} onChange={handleChange} placeholder='Enter company' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'LinkedIn URL'} required={true} />
                                    <TextField required fullWidth size='small' name="linkedin_url" variant="outlined" value={formData.linkedin_url} onChange={handleChange} type="url" placeholder='Enter URL' />
                                </Box>
                            </Grid>
                        </StyledGrid>

                        <Box height={100} />
                        <AppBar position="fixed" color="default" sx={{ top: 'auto', py: 2.5, bgcolor: 'white', bottom: 0 }}>
                            <Container>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Button type="button" onClick={handleGoBack} variant="outlined" color="primary">
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary" disabled={isPending}>
                                        Submit
                                    </Button>
                                </Stack>
                            </Container>
                        </AppBar>
                    </form>
                </Box>
            </Container>
        </>
    );
};

export default EditProfile;
