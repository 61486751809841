import api from "../utils/api";

export const fetchFounders = async ({ page = 1, limit = 10, search = '', sortBy = 'DESC' }) => {
    try {
        const response = await api.get('/founders', {
            params: { page, limit, search, sortBy }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addFounder = async (formData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const response = await api.post('/founders/add-founder', formData, config);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const editFounder = async (formData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const response = await api.patch(`/founders/edit-founder/${formData.founderId}`, formData.formData, config);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
