import React, { useState, useEffect } from 'react';
import { AppBar, Box, Breadcrumbs, Button, Chip, CircularProgress, Container, Grid, InputAdornment, Stack, styled, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../layout/Header';
import { addTopics, fetchTopics } from '../../services/linkedin.service';

const StyledGrid = styled(Grid)(({ theme }) => ({
    background: 'white',
    padding: 32,
    borderRadius: 32,
}));

const Label = ({ label, required }) => (
    <Typography variant='subtitle2' mb={0.5}>
        {label} {required && <span style={{ color: 'tomato' }}>*</span>}
    </Typography>
);

const AddTopic = () => {
    const navigate = useNavigate();
    const { mutate, isLoading: isMutating } = useMutation({
        mutationFn: addTopics,
        onSuccess: () => {
            toast.success('Added new topics successfully!');
            navigate('/linkedin-profile');
        },
        onError: (err) => {
            toast.error(err.message || 'Failed to add new topics');
        },
    });

    const { data, isLoading } = useQuery({
        queryKey: ['topics'],
        queryFn: fetchTopics,
    });

    const [text, setText] = useState('');
    const [topics, setTopics] = useState([]);

    useEffect(() => {
        if (data) {
            setTopics(data.data.map((item) => item.name)); // Assuming data.data is the array of topics
        }
    }, [data]);

    const handleChange = (e) => {
        const { value } = e.target;
        setText(value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && text.trim()) {
            e.preventDefault();
            const trimmedText = text.trim();
            if (!topics.includes(trimmedText)) {
                setTopics((prevTopics) => [...prevTopics, trimmedText]);
            }
            setText('');
        }
    };

    const handleDelete = (topicToDelete) => {
        setTopics((prevTopics) => prevTopics.filter((topic) => topic !== topicToDelete));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({ topics });
    };

    // if (isLoading) {
    //     return <Typography>Loading...</Typography>; // Add your loading state UI here
    // }

    return (
        <>
            <Header />
            <Container>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4 }}>
                    <Link style={{ color: '#858585' }} to="/linkedin-profile">Home</Link>
                    <Typography color="text.primary">Add LinkedIn Profile</Typography>
                </Breadcrumbs>

                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mt={3}>
                    <Box>
                        <Typography variant="h6" fontWeight={600} color="textPrimary">
                            Add New Topic
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            Provide your details to add or remove Hot Topics.
                        </Typography>
                    </Box>
                </Stack>

                <Box mt={5}>
                    <form onSubmit={handleSubmit}>
                        <StyledGrid container mt={4}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    Topics
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    Please provide the following information.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box mb={2}>
                                    <Label label="Hot Topic" required />
                                    <TextField
                                        endorment
                                        disabled={isLoading}
                                        InputProps={{
                                            endAdornment: (
                                                isLoading ? (
                                                    <InputAdornment position="end">
                                                        <CircularProgress size={20} />
                                                    </InputAdornment>
                                                ) : null
                                            )
                                        }}
                                        fullWidth
                                        size="small"
                                        name="topic"
                                        variant="outlined"
                                        value={text}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Enter topic"
                                    />
                                </Box>
                                <Stack direction="row" flexWrap="wrap" gap={1}>
                                    {topics.map((topic, index) => (
                                        <Chip
                                            key={index}
                                            label={topic}
                                            onDelete={() => handleDelete(topic)}
                                            color="primary"
                                        />
                                    ))}
                                </Stack>
                            </Grid>
                        </StyledGrid>

                        <Box height={100} />
                        <AppBar position="fixed" color="default" sx={{ top: 'auto', py: 2.5, bgcolor: 'white', bottom: 0 }}>
                            <Container>
                                <Stack direction="row" justifyContent="space-between">
                                    <Link to='/linkedin-profile'>
                                        <Button type="button" variant="outlined" color="primary">
                                            Cancel
                                        </Button>
                                    </Link>
                                    <Button type="submit" variant="contained" color="primary" disabled={isMutating}>
                                        Submit
                                    </Button>
                                </Stack>
                            </Container>
                        </AppBar>
                    </form>
                </Box>
            </Container>
        </>
    );
};

export default AddTopic;
