import React, { useState, useRef } from 'react';
import { Avatar, Stack, styled, Typography } from '@mui/material';
import { User } from 'phosphor-react';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 100,
    height: 100,
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.primary.lighter,
        border: '2px dashed',
        borderColor: theme.palette.primary.main,
        '& svg circle, svg path': {
            stroke: theme.palette.primary.main
        }
    },
}));

const AvatarUpload = ({ defaultImage = null, name, onChange }) => {
    const [previewUrl, setPreviewUrl] = useState(defaultImage || null);
    const [error, setError] = useState('');

    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        // File size validation (5MB limit)
        if (file && file.size > 5 * 1024 * 1024) {
            setError('File size exceeds 5MB limit.');
            return;
        }

        // File type validation (accept only image files)
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (file && !allowedTypes.includes(file.type)) {
            setError('Invalid file type. Please upload a JPEG, PNG, or GIF image.');
            return;
        }

        // Reset error if valid file is selected
        setError('');

        // Handle file preview and onChange callback
        onChange(e);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleClickAvatar = () => {
        fileInputRef.current.click();
    };

    return (
        <Stack justifyContent={'center'} alignItems={'center'}>
            <StyledAvatar onClick={handleClickAvatar}>
                {previewUrl ? (
                    <img src={previewUrl} alt="Profile Preview" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }} />
                ) : (
                    <User size={32} />
                )}
            </StyledAvatar>
            {error && (
                <Typography variant="caption" textAlign={'center'} mt={1} color="error">
                    {error}
                </Typography>
            )}
            <input name={name} ref={fileInputRef} id="avatar-upload" type="file" accept="image/jpeg, image/png, image/gif" hidden onChange={handleFileChange} />
        </Stack>
    );
};

export default AvatarUpload;
