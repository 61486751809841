import {
    Avatar,
    Box,
    Button,
    Chip,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    DotsThreeVertical,
    PencilSimple,
    Plus,
    ToggleLeft,
    ToggleRight
} from "phosphor-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "../../component/SearchBox";
import Header from "../../layout/Header";
import { fetchLinkedInUsers, toggleProfileStatus } from "../../services/linkedin.service";
import { getLinkedInUsername } from "../../utils/helper";
import moment from "moment";
import { toast } from "react-toastify";

const ActionMenu = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        navigate(`/linkedin-profile/edit-profile`, { state: row });
    };

    const toggleStatus = async () => {
        try {
            await toggleProfileStatus({ profileId: row._id });
            toast.success('Profile Status changed successfully!');
            queryClient.invalidateQueries(['linkedin-profile']);
        } catch (err) {
            toast.error('Something went wrong!');
        }
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <DotsThreeVertical />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}>
                    <Stack direction="row" alignItems="center" gap={2}>
                        <PencilSimple size={20} color="#79767E" />
                        <Typography variant="body2" color="text.light">Edit Profile</Typography>
                    </Stack>
                </MenuItem>
                <MenuItem onClick={toggleStatus}>
                    <Stack direction="row" alignItems="center" gap={2}>
                        {
                            row.isActive ? (
                                <ToggleLeft size={20} color="red" weight="fill" />
                            ) : (
                                <ToggleRight size={20} color="green" weight="fill" />
                            )
                        }
                        <Typography variant="body2" color="text.light">{!row.isActive ? 'Activate' : 'Deactivate'}</Typography>
                    </Stack>
                </MenuItem>
            </Menu>
        </div>
    );
};

const columns = [
    { field: '_id', headerName: 'ID' },
    {
        field: 'fullName',
        headerName: 'Full Name',
        flex: 1,
        renderCell: ({ row }) => (
            <Stack direction="row" height="100%" alignItems="center" gap={1}>
                <Avatar src={row.image} alt="LinkedIn profile" />
                <Typography variant="body2">{row.fullName}</Typography>
            </Stack>
        ),
    },
    {
        field: 'profileUrl',
        headerName: 'LinkedIn',
        flex: 1,
        renderCell: ({ row }) => {
            const username = getLinkedInUsername(row.profileUrl);
            return username ? (
                <Link style={{ color: 'black' }} onClick={(event) => event.stopPropagation()} target="_blank" to={row.profileUrl}>
                    {username}
                </Link>
            ) : (
                <></>
            );
        }
    },
    {
        field: 'lastScraped',
        headerName: 'Last Scraped',
        flex: 1,
        renderCell: ({ row }) => (
            <Stack direction="row" height="100%" alignItems="center" >
                <Typography variant="body2" >
                    {row.lastScraped ? moment(row.lastScraped).format('MMM DD, YYYY') : '-'}
                </Typography>
            </Stack>
        )
    },
    {
        field: 'isActive',
        headerName: 'Status',
        flex: 1,
        renderCell: ({ row }) => (
            <Stack direction="row" height="100%" alignItems="center" >
                <Chip label={row.isActive ? "Active" : "Inactive"} color={row.isActive ? "success" : "error"} />
            </Stack>
        )
    },
    {
        headerName: 'Action',
        width: 120,
        renderCell: ({ row }) => <ActionMenu row={row} />,
    },
];

const LinkedInProfile = () => {
    const [search, setSearch] = useState('');
    const [pagination, setPagination] = useState({
        pageSize: 10,
        page: 0,
    });

    const query = {
        page: pagination.page + 1,
        limit: pagination.pageSize,
        search,
    };

    const { data, isLoading } = useQuery({
        queryKey: ['linkedin-profile', query],
        queryFn: () => fetchLinkedInUsers(query),
        placeholderData: keepPreviousData
    });

    const handleSearchChange = (value) => {
        setSearch(value);
        setPagination((prev) => ({ ...prev, page: 0 }));
    };

    const handlePaginationChange = (model) => {
        setPagination((prev) => ({
            ...prev,
            ...model,
        }));
    };

    return (
        <>
            <Header />
            <Container maxWidth="lg" sx={{ pb: '100px' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={4} mb={6}>
                    <Box>
                        <Typography variant="h4" color="text.main" fontWeight={600}>LinkedIn Profiles</Typography>
                        <Typography variant="body1" color="text.light">
                            This directory stores LinkedIn profiles that have been scraped. <br />
                            Here you can view, manage, and update profile information.
                        </Typography>
                    </Box>
                    <Link to="/linkedin-profile/add-profile">
                        <Button variant="contained" size="large" endIcon={<Plus />}>
                            Add New Profile
                        </Button>
                    </Link>
                </Stack>
                <Stack mb={4}>
                    <SearchBox onSearchChange={handleSearchChange} isLoading={isLoading} />
                </Stack>
                <Box sx={{ height: '87vh', width: '100%' }}>
                    <DataGrid
                        rowHeight={60}
                        rows={data?.data || []}
                        columns={columns}
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                            columns: {
                                columnVisibilityModel: {
                                    _id: false,
                                },
                            },
                        }}
                        loading={isLoading}
                        paginationMode="server"
                        rowCount={data?.total || 0}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={pagination}
                        onPaginationModelChange={handlePaginationChange}
                        getRowId={(row) => row._id}
                    />
                </Box>
            </Container>
        </>
    );
};

export default LinkedInProfile;
