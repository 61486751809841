import { Avatar, Divider, Menu, MenuItem, Stack, styled, Typography } from "@mui/material";
import { SignOut } from "phosphor-react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slice/auth.slice";

const StyledStack = styled(Stack)(({ theme }) => ({
    cursor: 'pointer',
    ':hover': {
        background: theme.palette.text.lighter,
    },
}));

const AccountPopover = () => {
    const authUser = useSelector((state) => state.auth);

    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
    }
    return (
        <div>
            <StyledStack direction={'row'} gap={2} px={1.5} py={0.5} borderRadius={1} alignItems={'center'} onClick={handleClick}>
                <Avatar sx={{ bgcolor: 'lightblue', }}>{authUser.name.charAt(0)}</Avatar>
                <Stack>
                    <Typography variant="subtitle1" fontWeight={600} mb={-0.5} color={'text.main'}>{authUser.name}</Typography>
                    <Typography variant="subtitle2" textTransform={'capitalize'} color={'text.light'}>{authUser.role}</Typography>
                </Stack>
            </StyledStack>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{ mt: 1 }}
            >
                <Stack justifyContent={'center'} alignItems={'center'} px={4} py={2} >
                    <Avatar sx={{ bgcolor: 'lightblue', height: '60px', 'width': '60px' }}>{authUser.name.charAt(0)}</Avatar>
                    <Typography variant="body1" fontWeight={600} mt={1}>{authUser.email}</Typography>
                    <Typography variant="body2" textTransform={'capitalize'} >{authUser.role}</Typography>
                </Stack>
                <Divider />
                <MenuItem sx={{ width: '200px' }} onClick={handleLogout}>
                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                        <SignOut size={20} color="red" />
                        <Typography color={'red'}>Sign out</Typography>
                    </Stack>
                </MenuItem>
            </Menu>
        </div>
    );
};
export default AccountPopover;