import api from "../utils/api";

export const fetchReleventProfiles = async ({ page = 1, limit = 10, search = '', sortBy = 'DESC', founder_id }) => {
    try {
        const response = await api.get('/releventProfile', {
            params: { founder_id, page, limit, search, sortBy }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addReleventProfile = async ({ founder_id, body }) => {
    try {
        const response = await api.post(`/releventProfile/${founder_id}`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const editReleventProfile = async ({ profile_id, body }) => {
    try {
        const response = await api.patch(`/releventProfile/${profile_id}`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};