import React, { useState } from "react";
import { Button, Card, CardContent, CardMedia, Dialog, DialogContent, DialogTitle, Stack, Typography, CircularProgress, IconButton } from "@mui/material";
import NotFoundImage from '../asset/image/notfound.jpg';
import { toast } from 'react-toastify';
import { generatePostIdea } from "../services/genai.service";
import { useMutation } from "@tanstack/react-query";
import { X } from "phosphor-react";

const NewsCard = ({ title, text, url, image, publish_date, author }) => {
    const [open, setOpen] = useState(false);
    const [postIdea, setPostIdea] = useState(null);

    const mutation = useMutation({
        mutationFn: generatePostIdea,
        onSuccess: (data) => {
            setPostIdea(data.data.content);
        },
        onError: (error) => {
            toast.error(`Failed: ${error.message}`);
            setPostIdea(null);
        },
    });

    const handleGenerate = () => {
        setOpen(true);
        mutation.mutate({ content: text });
    };

    const handleClose = () => {
        setOpen(false);
        setPostIdea(null);
    };

    return (
        <>
            <Card>
                {image && (
                    <CardMedia
                        component="img"
                        height={200}
                        image={image}
                        alt={title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = NotFoundImage;
                        }} />
                )}
                <CardContent>
                    <Typography component="div" variant="h6" fontWeight={600}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" component="div">
                        {author} - {new Date(publish_date).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {text.slice(0, 300)}...
                    </Typography>
                    <Stack direction={'row'} gap={2} mt={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            href={url}
                            target="_blank"
                            fullWidth
                        >
                            Read More
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleGenerate}
                            disabled={mutation.isLoading}
                        >
                            Generate
                        </Button>
                    </Stack>
                </CardContent>
            </Card>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Generated Post Idea</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <X />
                </IconButton>
                <DialogContent>
                    {mutation.isPending ? (
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} py={4}>
                            <CircularProgress />
                            <Typography>Generating post idea...</Typography>
                        </Stack>
                    ) : postIdea ? (
                        <>
                            <Typography variant="h6" component="div" fontWeight={600}>
                                Hookline: {postIdea.hookline}
                            </Typography>
                            <Typography variant="body1" component="div" mt={2}>
                                {postIdea.content}
                            </Typography>
                            <Typography variant="body2" component="div" mt={2}>
                                Key Points:
                            </Typography>
                            <ul>
                                {postIdea.keypoints.map((point, index) => (
                                    <li key={index}>
                                        <Typography variant="body2" component="div">
                                            {point}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : (
                        <Typography variant="body1" component="div">
                            Failed to generate post idea.
                        </Typography>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

export default NewsCard;
