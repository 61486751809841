import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      lighter: '#e8f0ff',
      light: '#bad2ff',
      main: '#215bfb',
      dark: '#0019ff',
      darker: '#1641ba',
    },
    text: {
      main: '#2f2f2f',
      light: '#858585',
      disabled: '#dddddd',
      lighter: '#F6F6F6',
    },
    success:{
      light:'#E5FFD8',
      main:'#2BA84A'
    }
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          background:'white',
          border:'none',
          '& .MuiDataGrid-container--top  [role=row]':{
            background: '#F6F8FA',
            borderRadius: '20px',
          },
          '& .MuiDataGrid-topContainer::after':{
            background:'transparent'
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background:'white',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: '26px',
          fontSize:14,
        },
      },
    },
  },
});

export default theme;
