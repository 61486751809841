import styled from '@emotion/styled';
import { AppBar, Box, Container, Slide, Stack, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../asset/svg/logo';
import AccountPopover from './AccountPopover';


const StyledLink = styled(Link)(({ theme, active }) => ({
    textDecoration: 'none',
    padding: '6px 12px',
    borderRadius: '4px',
    color: active === 'true' ? theme.palette.primary.main : theme.palette.text.light,
    ':hover': {
        background: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
    },
}));

function HideOnScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Header = (props) => {
    
    const routes = [
        // {
        //     name: 'Dashboard',
        //     href: '/',
        // },
        {
            name: 'Founders',
            href: '/',
        },
        {
            name: 'LinkedIn Posts',
            href: '/posts',
        },
        {
            name: 'LinkedIn Profile',
            href: '/linkedin-profile',
        },
        {
            name: 'Add Topics',
            href: '/hot-topic/add-topic',
        },
    ]
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState(location.pathname || '/');

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar color="transparent" sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", bgcolor: 'white' }} elevation={0}>
                    <Toolbar>
                        <Container maxWidth="lg">
                            <Stack direction={'row'} alignItems={'center'} >
                                <Box alignSelf={'baseline'} mt={0.8}>
                                    <Logo />
                                </Box>
                                <Stack direction={'row'} ml={10} alignItems={'center'} gap={3.5} justifyContent={'space-between'}>
                                    {
                                        routes.map((route, index) => (
                                            <StyledLink key={index} to={route.href} active={(route.href === currentTab).toString()} onClick={() => { setCurrentTab(route.href) }}>
                                                <Typography variant='subtitle1' fontWeight={600} >{route.name}</Typography>
                                            </StyledLink>
                                        ))
                                    }
                                </Stack>
                                <Box sx={{ ml: 'auto' }}>
                                    <AccountPopover />
                                </Box>
                            </Stack>
                        </Container>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box height={'70px'} />
        </>
    )
}

export default Header;
