import api from "../utils/api";

export const fetchReleventNews = async ({ founder_id }) => {
    try {
        const response = await api.get('/scrapper/news', {
            params: { founder_id }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};