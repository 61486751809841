import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);

    return isAuthenticated ? <>{children}</> : <Navigate to='/login' />;
};

export default AuthGuard;
