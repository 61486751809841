import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  email: '',
  role: '',
  isLoggedIn: false,
  accessToken: '',
  refreshToken: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { user, tokens } = action.payload;
      state.name = user.name;
      state.email = user.email;
      state.role = user.role;
      state.isLoggedIn = true;
      state.accessToken = tokens.access.token;
      state.refreshToken = tokens.refresh.token;
    },
    logout(state) {
      state.name = '';
      state.email = '';
      state.role = '';
      state.isLoggedIn = false;
      state.accessToken = '';
      state.refreshToken = '';
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
