const InsightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" id="interest-creation-large" data-supported-dps="48x48">
            <g>
                <path fill="none" d="M0 0h48v48H0z" />
                <path d="M27 44h-6a2 2 0 01-2-2v-5h10v5a2 2 0 01-2 2z" fill="#c07800" />
                <path d="M21 37l8 .07a11.25 11.25 0 01.09-3.57 6.61 6.61 0 01.78-1.63 5.16 5.16 0 01.63-.69l.14-.12A11.75 11.75 0 0035 22.17a11 11 0 00-22 0v.06a11.43 11.43 0 004.13 8.66 10.73 10.73 0 011.77 2.79 9.47 9.47 0 01.8 3.08l.3.24" fill="#f5bb5c" />
                <path d="M12 5.82L14.42 9M36 5.82L33.58 9M24 2v4.77" fill="none" stroke="#5d3b01" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.91" />
                <path d="M27 44h-6a2.21 2.21 0 01-2-2.1V37h10v4.9a2.21 2.21 0 01-2 2.1z" fill="none" stroke="#5d3b01" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path d="M29 38c0-3.63.41-4.47.41-4.47a6.34 6.34 0 011.89-3 12.84 12.84 0 001.28-1.38 11.86 11.86 0 001.81-3.24 11.39 11.39 0 00.65-3.7v-.06A11.1 11.1 0 0024 11h0a11.09 11.09 0 00-11 11.17v.06a11.39 11.39 0 00.65 3.7 11.86 11.86 0 001.81 3.24 12.84 12.84 0 001.28 1.38 6.34 6.34 0 011.89 3s.37.84.37 4.45" fill="none" stroke="#5d3b01" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>

    )
}

export default InsightIcon;