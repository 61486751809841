import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useRef, useState } from 'react';

const SearchBox = ({ onSearchChange, isLoading, sx }) => {
    const instance = useRef(null);
    const [search, setSearch] = useState('');

    const handleSearchChange = (value) => {
        setSearch(value);
        clearTimeout(instance.current);
        instance.current = setTimeout(() => {
            onSearchChange(value)
        }, 600)
    };

    return (
        <TextField
            size="small"
            sx={{ width: '350px', ...sx }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <MagnifyingGlass />
                    </InputAdornment>
                ),
                endAdornment: (
                    isLoading ? (
                        <InputAdornment position="end">
                            <CircularProgress size={20} />
                        </InputAdornment>
                    ) : null
                )
            }}
            variant="outlined"
            placeholder="Search by name"
            value={search}
            onChange={(e) => handleSearchChange(e.target.value)}
        />
    );
};

export default SearchBox;
