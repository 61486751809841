import { Box, Chip, Container, Divider, Grid, InputAdornment, MenuItem, Select, Stack, TablePagination, Typography } from "@mui/material";
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { Chat, ChatCircle, Clock, ThumbsUp } from "phosphor-react";
import { useState } from "react";
import LinkedInPostCard from "../../component/LinkedinPostCard";
import LinkedInUserSelect from "../../component/LinkedInUserSelect";
import SearchBox from "../../component/SearchBox";
import Header from "../../layout/Header";
import { fetchLinkedInPosts, fetchTopics } from "../../services/linkedin.service";

const calculateEndDate = (value) => {
  switch (value) {
    case '24h':
      return moment().subtract(24, 'hours').format('YYYY-MM-DD');
    case '48h':
      return moment().subtract(48, 'hours').format('YYYY-MM-DD');
    case '1w':
      return moment().subtract(1, 'week').format('YYYY-MM-DD');
    case '1m':
      return moment().subtract(1, 'month').format('YYYY-MM-DD');
    case '3m':
      return moment().subtract(3, 'months').format('YYYY-MM-DD');
    case '1y':
      return moment().subtract(1, 'year').format('YYYY-MM-DD');
    default:
      return '';
  }
};

const Posts = () => {
  const [filters, setFilters] = useState({
    search: '',
    postedOn: '48h',
    contentLength: '',
    likes: '200',
    comments: '',
    sortBy: 'likesCount:DESC',
    userData: null,
  });

  const [topics, setTopics] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0
  });

  const query = {
    page: pagination.page + 1,
    limit: pagination.pageSize,
    search: `${filters.search}${topics.length > 0 ? `,${topics.join(',')}` : ''}`,
    maxContentLength: filters.contentLength,
    minLikes: filters.likes,
    minComments: filters.comments,
    sortBy: filters.sortBy,
    userId: filters.userData?._id || '',
    startDate: calculateEndDate(filters.postedOn),
  };

  const { data, isLoading } = useQuery({
    queryKey: ['linkedin-posts', query],
    queryFn: ({ queryKey }) => fetchLinkedInPosts(queryKey[1]),
    keepPreviousData: true
  });

  const { data: topicData, isLoading: topicLoader } = useQuery({
    queryKey: ['topics'],
    queryFn: fetchTopics,
  });

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      page: 0,
      pageSize: parseInt(event.target.value, 10)
    });
  };

  const handleTopClick = (topic) => {
    if (!topics.includes(topic)) {
      setTopics((prevTopics) => [...prevTopics, topic]);
    }
  }

  const handleRemoveTopic = (topicToDelete) => {
    setTopics((prevTopics) => prevTopics.filter((topic) => topic !== topicToDelete));
  }

  const dateOptions = [
    { value: '24h', label: '< 24 hours' },
    { value: '48h', label: '< 48 hours' },
    { value: '1w', label: '< 1 week' },
    { value: '1m', label: '< 1 month' },
    { value: '3m', label: '< 3 months' },
    { value: '1y', label: '< 1 year' }
  ];

  const lengthOptions = [
    { value: '50', label: '< 50 words' },
    { value: '100', label: '< 100 words' },
    { value: '200', label: '< 200 words' },
    { value: '500', label: '< 500 words' }
  ];

  const countOptions = [
    { value: '100', label: '100+' },
    { value: '200', label: '200+' },
    { value: '500', label: '500+' },
    { value: '1000', label: '1000+' },
    { value: '5000', label: '5000+' }
  ];

  const sortOptions = [
    { value: 'publishedDate:ASC', label: 'Posted At: ASC' },
    { value: 'publishedDate:DESC', label: 'Posted At: DESC' },
    { value: 'likesCount:ASC', label: 'Likes: Low to High' },
    { value: 'likesCount:DESC', label: 'Likes: High to Low' },
    { value: 'commentsCount:ASC', label: 'Comments: Low to High' },
    { value: 'commentsCount:DESC', label: 'Comments: High to Low' }
  ];

  return (
    <>
      <Header />
      <Container maxWidth='lg' sx={{ pb: '100px' }}>
        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mt={4} >
          <Box>
            <Typography variant="h4" color={'text.main'} fontWeight={600}>LinkedIn Insights</Typography>
            <Typography variant="body1" color={'text.light'}>
              Explore and Filter Engaging LinkedIn Posts Tailored to Your Interests
            </Typography>
          </Box>
        </Stack>
        {topicData?.data.length > 0 && <Box >
          <Typography my={1} variant="body1" >Hot Topics 🔥</Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {topicData?.data?.map((topic, index) => (
              <Chip
                key={index}
                label={topic.name}
                clickable={true}
                onClick={() => {
                  handleTopClick(topic.name)
                }}
              />
            ))}
          </Stack>
        </Box>}
        <Divider sx={{ my: 2 }} />
        <Box>
          <SearchBox
            sx={{ width: '100%' }}
            onSearchChange={(value) => handleFilterChange('search', value)}
            isLoading={isLoading}
          />
        </Box>
        <Stack mt={2} direction="row" justifyContent={'space-between'} spacing={2}>
          <Stack direction="row" spacing={2}>
            <Select
              value={filters.postedOn}
              onChange={(e) => handleFilterChange('postedOn', e.target.value)}
              displayEmpty
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <Clock size={23} />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>Posted at</MenuItem>
              {dateOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>

            <Select
              value={filters.contentLength}
              onChange={(e) => handleFilterChange('contentLength', e.target.value)}
              displayEmpty
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <Chat size={23} />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>Content Length</MenuItem>
              {lengthOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>

            <Select
              value={filters.likes}
              onChange={(e) => handleFilterChange('likes', e.target.value)}
              displayEmpty
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <ThumbsUp size={23} />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>Likes</MenuItem>
              {countOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>

            <Select
              value={filters.comments}
              onChange={(e) => handleFilterChange('comments', e.target.value)}
              displayEmpty
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <ChatCircle size={23} />
                </InputAdornment>
              }
            >
              <MenuItem value="" disabled>Comments</MenuItem>
              {countOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
            <LinkedInUserSelect
              value={filters.userData}
              handleChange={(val) => handleFilterChange('userData', val)}
            />
          </Stack>
          <Stack direction="row">
            <Select
              value={filters.sortBy}
              onChange={(e) => handleFilterChange('sortBy', e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>Sort By</MenuItem>
              {sortOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Stack direction={'row'} gap={2}>
          {Object.entries(filters).map(([key, value]) =>
            value && key !== 'search' && (
              <Chip
                key={key}
                label={`${key}: ${key === 'userData' ? value.fullName : value}`}
                onDelete={() => handleFilterChange(key, '')}
              />
            )
          )}
          {
            topics.map((topicName, key) => (
              <Chip
                key={key}
                label={topicName}
                onDelete={() => handleRemoveTopic(topicName)}
              />
            ))
          }
        </Stack>
        <Grid container spacing={3} mt={4}>
          {data?.data?.map((postItem, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <LinkedInPostCard {...postItem} />
            </Grid>
          ))}
        </Grid>
        <TablePagination
          component="div"
          count={data?.total || 0}
          page={pagination.page}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </>
  );
};

export default Posts;
