import { styled } from '@mui/material/styles';

const SegmentedBtn = styled('div')(({ theme, customTheme }) => ({
    display: 'inline-flex',
    borderRadius: '46px',
    overflow: 'hidden',
    '& .btn': {
        padding: '10px 15px',
        color: theme.palette.grey[500],
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        cursor: 'pointer',
        background:'white',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
        '&.active': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
        },
    },

}));

const SegmentedButton = ({ list, activeIndex, handleChange, theme }) => {
    return (
        <SegmentedBtn customTheme={theme}>
            {list.map((item, index) => {
                const activeClass = index === activeIndex ? 'active' : '';
                return (
                    <div
                        key={item}
                        className={`btn ${activeClass}`}
                        onClick={() => handleChange(index)}
                    >
                        {item}
                    </div>
                );
            })}
        </SegmentedBtn>
    );
};

export default SegmentedButton;
