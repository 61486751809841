import { CircularProgress, Stack } from "@mui/material";

const LoadingScreen = () => {
    return (
        <Stack alignItems={'center'} justifyContent={'center'} height={400} width={'100%'}>
            <CircularProgress />
        </Stack>
    )
}

export default LoadingScreen;