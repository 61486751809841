const SupportIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" id="support-creation-large" data-supported-dps="48x48">
            <path d="M14 32h-.69c-.79-.29-4.82-1.83-7.53-2.59a.86.86 0 01-.63-.73 2.4 2.4 0 01.37-1.77 1.4 1.4 0 011.19-.58 2.06 2.06 0 01.7.12 5.71 5.71 0 011.49.87l.19.15 1.25.87c.46.33.87.61 1.16.83l2.92 1.19c.39.18 2 .92 2 1.34s-1.86.37-2.25.38z" fill="#9983b1" />
            <path d="M42.75 44.52a74.76 74.76 0 01-11.84-1.23h-.21a67.24 67.24 0 01-8.77-1.82c-2.56-.77-5.08-1.77-7.52-2.73l-1.09-.43c-2.31-.9-4.19-1.66-5.9-2.39l-.64-.27a24.09 24.09 0 01-3-1.41c-1.33-.78-1.74-1.78-1.19-3v-.09a1.93 1.93 0 012-1.15h.11a5.61 5.61 0 01.58 0c2.12.23 8.27 2.57 9.48 3l4 .14h.1l9 .31c-.7-.64-2.45-1.75-6.75-2.86-.82-.21-1.56-.45-1.73-1a2.38 2.38 0 01.78-2.54 3.18 3.18 0 011.84-.42 9.74 9.74 0 012.28.27c.46.12.83.24 1.19.35a13.5 13.5 0 002.88.65A31 31 0 0133 29c5 1.36 6 3.87 6.59 5.38-.19-.62 0-.16.1-.39l.14-.2h.77c.86 0 2.5-.09 2.51-.09a.85.85 0 01.85.63c1.71 6.23-.64 9.69-.74 9.83a.66.66 0 01-.48.33z" fill="#bba9d1" />
            <path d="M14.63 11.35a5 5 0 00-7.13-.09l-.09.09a5.24 5.24 0 000 7.34l7.79 7.9 7.8-7.9a5.24 5.24 0 000-7.34 5.06 5.06 0 00-3.61-1.53 5 5 0 00-3.6 1.53l-.57.58z" fill="#df704d" fill-rule="evenodd" />
            <path d="M43.65 34.32a7.14 7.14 0 011.48 5 10.7 10.7 0 01-2.28 5.29c-.41.4-15.19-1.48-18.65-2.16S4.05 34.43 3.41 34s-.7-3.12 1.13-3.69 5.86 2.15 9.53 2.75a105.12 105.12 0 0012.68.94c2.48 0-2.68-2.16-3.9-2.64s-3.2-.87-3.61-2.16.92-2.24 2-2.24a30.41 30.41 0 015.89 1.48A19.6 19.6 0 0135 29.83a9.16 9.16 0 014.67 4.49c.33.68 3.62-.32 3.98 0z" fill="none" stroke="#493d57" stroke-width="2" />
            <path d="M5.47 30.17c-.93-2 .22-3.17 1.58-3.17s2.4 1.48 4.37 2.83a74.82 74.82 0 006.89 3.87" fill="none" stroke="#493d57" stroke-width="2" />
            <path d="M14.36 10.63a5.48 5.48 0 00-7.74.06 5.63 5.63 0 00-.08 7.83L15 27l8.43-8.49a5.62 5.62 0 001.52-4.86 5.54 5.54 0 00-3-4.08A5.22 5.22 0 0019.49 9a5.48 5.48 0 00-3.88 1.65l-.64.61z" fill="none" stroke="#77280c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            <path fill="none" d="M0 0h48v48H0z" />
        </svg>

    )
}
export default SupportIcon;