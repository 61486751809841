import { AppBar, Box, Button, Container, Grid, Stack, styled, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AvatarUpload from '../../../component/AvatarUpload';
import { addLinkedInProfile } from '../../../services/linkedin.service';
import { getLinkedInUsername } from '../../../utils/helper';

const StyledGrid = styled(Grid)(({ theme }) => ({
    background: 'white',
    padding: 32,
    borderRadius: 32,
}));

const Label = ({ label, required }) => {
    return (
        <Typography variant='subtitle2' mb={0.5}>
            {label} {required && <span style={{ color: 'tomato' }}>*</span>}
        </Typography>
    )
}

const SingleUpload = () => {

    const navigate = useNavigate();
    const { mutate, isPending } = useMutation({
        mutationFn: addLinkedInProfile,
        onSuccess: () => {
            toast.success('Added new profile successfully!');
            navigate('/linkedin-profile')
        },
        onError: (err) => {
            toast.error(err.message || 'Failed to add new profile');
        }
    })

    const [formData, setFormData] = useState({
        username: '',
        fullName: '',
        profileUrl: '',
        description: '',
        image: null
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        const tempName=getLinkedInUsername(formData.profileUrl);
        formDataToSend.append('username', tempName);
        formDataToSend.append('fullName', tempName);
        formDataToSend.append('profileUrl', formData.profileUrl);
        // formDataToSend.append('description', 'Dummy Data');

        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }

        mutate(formDataToSend);
    };


    return (
        <>
            <form onSubmit={handleSubmit}>
                {/* <StyledGrid container >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' fontWeight={600}>
                            LinkedIn Profile
                        </Typography>
                        <Typography variant='body2' color={'text.light'}>
                            Please upload a profile photo for the LinkedIn profile.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Label label={'Profile Pic'} />
                        <Stack justifyContent={'center'} alignItems={'center'} >
                            <AvatarUpload name='image' onChange={handleChange} />
                        </Stack>
                    </Grid>
                </StyledGrid> */}
                <StyledGrid container mt={4}  >
                    <Grid item xs={12} sm={6}>
                        <Typography variant='body1' fontWeight={600}>
                            Profile Details
                        </Typography>
                        <Typography variant='body2' color={'text.light'}>
                            All fields are required. Please provide the following information.

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* <Box mb={2}>
                            <Label label={'User Name'} required={true} />
                            <TextField required size='small' fullWidth name="username" variant="outlined" value={formData.username} onChange={handleChange} placeholder='Enter User Name' />
                        </Box> */}
                        {/* <Box mb={2}>
                            <Label label={'Full Name'} required={true} />
                            <TextField required fullWidth size='small' name="fullName" variant="outlined" value={formData.fullName} onChange={handleChange} placeholder='Enter Full Name' />
                        </Box> */}

                        <Box mb={2}>
                            <Label label={'Profile URL'} required={true} />
                            <TextField required fullWidth size='small' name="profileUrl" variant="outlined" value={formData.profileUrl} onChange={handleChange} type="url" placeholder='Enter Profile URL' />
                        </Box>
                        {/* <Box mb={2}>
                            <Label label={'Description'} required={true} />
                            <TextField required fullWidth multiline rows={6} size='small' name="description" variant="outlined" value={formData.description} onChange={handleChange} placeholder='Enter Description' />
                        </Box> */}
                    </Grid>
                </StyledGrid>


                <Box height={100} />
                <AppBar position="fixed" color="default" sx={{ top: 'auto', py: 2.5, bgcolor: 'white', bottom: 0 }}>
                    <Container>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Link to='/linkedin-profile'>
                                <Button type="button" variant="outlined" color="primary">
                                    Cancel
                                </Button>
                            </Link>
                            <Button type="submit" variant="contained" color="primary" disabled={isPending}>
                                Submit
                            </Button>
                        </Stack>
                    </Container>
                </AppBar>
            </form>
        </>
    );
};

export default SingleUpload;
