import api from "../utils/api";

export const fetchFounderTemplates = async ({ page = 1, limit = 10, search = '', sortBy = 'DESC', founder_id }) => {
    try {
        const response = await api.get('/template', {
            params: { founder_id, page, limit, search, sortBy }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addFounderTemplate = async ({ founder_id, body }) => {
    try {
        const response = await api.post(`/template/${founder_id}`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const editFounderTemplate = async ({ template_id, body }) => {
    try {
        const response = await api.patch(`/template/${template_id}`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};