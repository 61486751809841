export const validateLocalPhoneNumber = (number) => {
    const firstDigit = parseInt(number.trim()[0]);
    if (number.trim().length === 10 && firstDigit >= 6 && firstDigit <= 9) {
        return true;
    }
    return false;
};

export const validateForeignPhoneNumber = (number) => {
    if (!number.trim()) {
        return false;
    }

    if (number.trim().length < 7 || number.trim().length > 15) {
        return false;
    }

    return true;
};


export const validatePhoneNumber = (code, number) => {
    if (!code || !number) {
        return;
    }
    let codeString = code.toString();
    let numberString = number.toString();
    const isValidNumber = /^\d+$/.test(numberString); //Number only

    if (!isValidNumber) {
        return false;
    }
    if (codeString === "91") {
        return validateLocalPhoneNumber(numberString);
    } else {
        return validateForeignPhoneNumber(numberString);
    }
}
export const isEmpty = (val) => {
    if (!val) {
        return true;
    }
    return val?.toString().trim() === '';
}
