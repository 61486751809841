const LoveIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" id="empathy-creation-large" data-supported-dps="48x48">
            <g>
                <path fill="none" d="M0 0h48v48H0z" />
                <path d="M22.9 14.9a9.91 9.91 0 10-14 14L24 44l15.1-15.07a10 10 0 00-2.67-16 9.55 9.55 0 00-4.33-1 9.84 9.84 0 00-7 2.93L24 16z" fill="#df704d" fill-rule="evenodd" />
                <path d="M22.9 14.88a9.88 9.88 0 00-14 13.94L24 43.8l15.1-15A9.86 9.86 0 0036.43 13a9.55 9.55 0 00-4.33-1h0a9.83 9.83 0 00-7 2.92L24 16z" fill="none" stroke="#77280c" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>

    )
}

export default LoveIcon;