import { Avatar, Box, Card, CardContent, IconButton, Stack, styled, Typography } from '@mui/material';
import { ChatCircle, Heart, UploadSimple } from 'phosphor-react';
import React, { useState } from 'react';
import moment from 'moment';
import PostDetailPopUp from './PostDetailPopUp';
import REACTION_MAP from '../constent/reactionMap';

const StyledPlaceholder = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    "&::before": {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'black',
        zIndex: -1,
        opacity: 0.5,
        filter: 'blur(8px)',
    }
}));
const ImageGrid = ({ imgUrl }) => {
    const maxImages = 3;
    const totalImages = imgUrl.length;
    if (imgUrl.length === 0) {
        return <></>
    }
    return (
        <Stack direction={'row'} flexWrap={'wrap'} height={350} >
            {imgUrl.slice(0, maxImages).map((url, index) => (
                <Box
                    key={index}
                    sx={{
                        position: 'relative',
                        flex: '50%',
                        width: '100%',
                        border: '1.5px solid white'
                    }}
                >
                    <img
                        src={url}
                        alt={`Post ${index + 1}`}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: totalImages >= 2 ? 'cover' : 'contain',
                        }}
                    />

                    {(index === 2 && totalImages > maxImages) ? (
                        <StyledPlaceholder >
                            <div>+ {totalImages - maxImages}</div>
                        </StyledPlaceholder>
                    ) : <></>}
                </Box>
            ))}
        </Stack>
    );
};

const LinkedInPostCard = (postData) => {
    const {
        imageURL,
        likesCount,
        commentsCount,
        sharesCount,
        content,
        reactionTypeCounts,
        publishedDate,
        userId,
        videoURL
    } = postData;
    const hasMedia = imageURL.length > 0 || videoURL;

    const [openDetailDialog, setOpenDetailDialog] = useState(false);

    const handleOpen = () => {
        setOpenDetailDialog(true);
    }
    const handleClose = () => {
        setOpenDetailDialog(false);
    }
    return (
        <>
            <PostDetailPopUp open={openDetailDialog} onClose={handleClose} postData={postData} />
            <Card sx={{ marginBottom: 2, cursor: 'pointer' }} onClick={handleOpen}>
                <CardContent>
                    {/* Post Header */}
                    <Box display="flex" alignItems="center" marginBottom={2}>
                        <Avatar alt="User" src={userId.image} />
                        <Box marginLeft={2}>
                            <Typography variant="subtitle1" fontWeight={500} mb={-1}>{userId.fullName}</Typography>
                            <Typography variant="caption" color="textSecondary">
                                {moment(publishedDate).format('YYYY/MM/DD')}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Post Content */}
                    <Typography variant="body1" gutterBottom sx={{
                        whiteSpace: 'pre-wrap',
                        ...(hasMedia ? {
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        } : {
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 20,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        })
                    }}>
                        {content}
                    </Typography>
                </CardContent>

                <ImageGrid imgUrl={imageURL} />
                {videoURL && <video src={videoURL} controls style={{ width: '100%', height: '350px' }} />}
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Stack direction={'row'} gap={1}>
                            {reactionTypeCounts.map((reaction) => (
                                <Stack direction={'row'} alignItems={'center'} gap={0.5} key={reaction._id}>
                                    <Box width={28} height={28} position={'relative'} sx={{
                                        svg: {
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%'
                                        }
                                    }}>
                                        {REACTION_MAP[reaction.reactionType]}
                                    </Box>
                                    <Typography variant="body2" color="textSecondary">
                                        {reaction.count}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Box>

                    <Box display="flex" justifyContent="space-between" marginTop={2}>
                        <Box display="flex" alignItems="center">
                            <IconButton size="small">
                                <Heart />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {likesCount}
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <IconButton size="small">
                                <ChatCircle />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {commentsCount}
                            </Typography>
                        </Box>

                        <Box display="flex" alignItems="center">
                            <IconButton size="small">
                                <UploadSimple />
                            </IconButton>
                            <Typography variant="body2" color="textSecondary">
                                {sharesCount}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

export default LinkedInPostCard;
