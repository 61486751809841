import { AppBar, Box, Button, Container, Stack, Typography } from "@mui/material";
import { ArrowsClockwise, DownloadSimple, File, UploadSimple, WarningCircle } from "phosphor-react";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UploadMultiIcon from '../../../asset/svg/upload-multi.svg'
import UploadSuccessIcon from '../../../asset/svg/upload-success.svg'
import { downloadExcel, readExcel } from "../../../utils/csv-helper";
import { styled } from '@mui/system';
import { isEmpty } from "../../../utils/validator";
import { getLinkedInUsername } from "../../../utils/helper";
import { useMutation } from "@tanstack/react-query";
import { bulkuploadProfile } from "../../../services/linkedin.service";
import { toast } from "react-toastify";

const UploadedCard = styled(Box)(({ theme, isInvalidFile }) => ({
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: isInvalidFile ? theme.palette.error.light : theme.palette.success.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.upload-card-left': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(3),
        color: isInvalidFile ? theme.palette.error.main : theme.palette.success.main,
    },
    '.upload-card-right': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '.download-link': {
            ...theme.typography.body2,
            color: theme.palette.grey[600],
            cursor: 'pointer',
        },
        '.upload-file': {
            padding: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            color: isInvalidFile ? theme.palette.error.main : theme.palette.success.main,
            '&:hover': {
                backgroundColor: isInvalidFile
                    ? theme.palette.error.light
                    : theme.palette.success.light,
            },
        },
    },
}));

const InvalidContainer = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    '.invalid-card': {
        padding: theme.spacing(2.5),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[300],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.invalid-card-left': {
            '.warning-text': {
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1.25),
                color: theme.palette.warning.main,
                svg: {
                    width: 18,
                    height: 18,
                    path: {
                        fill: theme.palette.warning.main,
                    },
                },
            },
            '.warning-subtitle': {
                color: theme.palette.grey[800],
                span: {
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightBold,
                },
            },
        },
        '.invalid-card-right': {
            button: {
                backgroundColor: theme.palette.common.white,
                color: theme.palette.grey[600],
                '&:hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light,
                    svg: {
                        path: {
                            fill: theme.palette.primary.main,
                        },
                    },
                },
            },
        },
    },
    '.invalid-bottom': {
        marginTop: theme.spacing(2),
        color: theme.palette.grey[600],
        span: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.warning.main,
        },
        button: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.warning.main,
            paddingLeft: 0,
            cursor: 'pointer',
        },
    },
}));



const ContactUploadedScreen = ({ handleClick, csvFile, isInvalidFile, validRows, invalidRows, duplicateRows }) => {
    const downloadFile = () => {
        const url = URL.createObjectURL(new Blob([csvFile]));
        const a = document.createElement('a');
        a.href = url;
        a.download = csvFile.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const downloadRows = (rows, fileName) => {
        downloadExcel(rows, fileName);
    };

    const valid = !isInvalidFile && !invalidRows.length && !duplicateRows.length;

    return (
        <Stack
            sx={{
                borderRadius: 2,
                background: 'white',
                p: 4,
                alignItems: 'center',
            }}
        >
            <img src={UploadSuccessIcon} alt='upload-img' />
            <UploadedCard isInvalidFile={isInvalidFile} mt={4}>
                <Box className="upload-card-left">
                    <File size={24} weight="fill" color={isInvalidFile ? 'red' : 'green'} />
                    <Typography variant="body1" fontWeight="bold">
                        {isInvalidFile ? 'INVALID FILE' : 'FILE VALIDATED'}
                    </Typography>
                </Box>
                <Box className="upload-card-right">
                    <Typography className="download-link" onClick={downloadFile}>
                        {csvFile.name}
                    </Typography>
                    <Box className="upload-file" onClick={handleClick}>
                        <ArrowsClockwise />
                        Reupload
                    </Box>
                </Box>
            </UploadedCard>

            <InvalidContainer mt={2}>
                {invalidRows.length > 0 && (
                    <Box className="invalid-card">
                        <Box className="invalid-card-left">
                            <Typography className="warning-text">
                                <WarningCircle /> {invalidRows.length} invalid URL found
                            </Typography>
                            <Typography className="warning-subtitle">
                                You can download all the invalid URL, <span>rectify and upload later</span>
                            </Typography>
                        </Box>
                        <Box className="invalid-card-right">
                            <Button className="tertiary-btn" onClick={() => downloadRows(invalidRows, 'invalidRows.xlsx')}>
                                <DownloadSimple size={20} style={{ marginRight: 8 }} /> Download invalid URL
                            </Button>
                        </Box>
                    </Box>
                )}
                {duplicateRows.length > 0 && (
                    <Box className="invalid-bottom">
                        <Typography>
                            In the file uploaded, <span>{duplicateRows.length} URL have been identified as duplicates.</span> Upon proceeding, duplicates will be merged. To get a list of duplicate URL from the uploaded file,{' '}
                            <Button onClick={() => downloadRows(duplicateRows, 'duplicateRows.xlsx')}>click here</Button>
                        </Typography>
                    </Box>
                )}
            </InvalidContainer>

            {valid && (
                <Typography variant="body1" mt={2}>Congratulations!🎊 All URL are valid and ready to be added.</Typography>
            )}
        </Stack>
    );
};


const UploadScreen = ({ handleClick }) => {
    return (
        <>
            <Stack
                sx={{
                    borderRadius: 2,
                    background: 'white',
                    p: 4
                }}
                alignItems={'center'}
            >
                <img src={UploadMultiIcon} alt="upload-icon" />
                <Typography mb={2} color={'text.secondary'}>
                    <b>Upload the file </b>with your data filled by clicking below
                </Typography>

                <Button variant="contained" startIcon={<UploadSimple />} onClick={handleClick}> Upload Filled File</Button>
            </Stack>

        </>
    )
}

const MultiUpload = () => {
    const fileType = ['text/csv', "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [csvFile, setCsvFile] = useState(null);
    const [isInvalidFile, setIsInvalidFile] = useState(false);
    const [validRows, setValidRows] = useState([]);
    const [invalidRows, setInvalidRows] = useState([]);
    const [duplicateRows, setDuplicateRows] = useState([]);
    const handleUpload = async (event) => {
        try {
            const file = event.target.files[0];
            if (!file) {
                console.log('File required');
                return;
            }
            setIsInvalidFile(false);
            setValidRows([]);
            setInvalidRows([]);
            setDuplicateRows([])

            if (!fileType.includes(file.type)) {
                console.log('Invalid File Type');
                setIsInvalidFile(true);
                setCsvFile(file);
                return;
            }

            setCsvFile(file);

            const { validRowsTemp, invalidRowsTemp, duplicateRowsTemp } = await processFileData(file);

            setValidRows(validRowsTemp);
            setInvalidRows(invalidRowsTemp);
            setDuplicateRows(duplicateRowsTemp);
        } catch (err) {
            setIsInvalidFile(true);
            console.log(err);
        }
    }
    const processFileData = async (file) => {
        const invalidRowsTemp = [];
        const validRowsTemp = [];
        const duplicateRowsTemp = [];
        const fileData = await readExcel(file);

        const encounteredURL = {};

        fileData.forEach(rowObject => {

            const { Name, URL } = rowObject;

            if (isEmpty(URL) || isEmpty(Name)) {
                rowObject.error = 'Mandatory Field is Missing';
                invalidRowsTemp.push(rowObject);
                return;
            }

            // 
            if (!getLinkedInUsername(URL)) {
                rowObject.error = 'Error in this Field';
                invalidRowsTemp.push(rowObject);
                return;
            }
            // Check Duplicate
            if (encounteredURL[URL]) {
                rowObject.error = 'Duplicate URL';
                duplicateRowsTemp.push(rowObject);
                return;
            }
            encounteredURL[URL] = true;


            validRowsTemp.push({
                username: getLinkedInUsername(URL),
                fullName: Name,
                profileUrl: URL
            });
        });

        return { validRowsTemp, invalidRowsTemp, duplicateRowsTemp };
    }
    const { mutate, isPending } = useMutation({
        mutationFn: bulkuploadProfile,
        onSuccess: () => {
            toast.success('Your profile upload is in progress and may take some time');
            navigate('/linkedin-profile')
        },
        onError: (err) => {
            toast.error(err.message || 'Failed to add new profile');
        }
    })
    const handleSubmit = async () => {
        mutate({
            data: validRows
        })
    }
    const handleClick = () => {
        inputRef.current.click();
    }
    const allowSubmit = validRows.length > 0 || isPending;
    return (
        <>
            <Box>
                <input type='file' ref={inputRef} onChange={handleUpload} hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                {csvFile ? <ContactUploadedScreen handleClick={handleClick} csvFile={csvFile} isInvalidFile={isInvalidFile} validRows={validRows} invalidRows={invalidRows} duplicateRows={duplicateRows} /> : <UploadScreen handleClick={handleClick} />}
            </Box>
            <Box height={100} />
            <AppBar position="fixed" color="default" sx={{ top: 'auto', py: 2.5, bgcolor: 'white', bottom: 0 }}>
                <Container>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to='/linkedin-profile'>
                            <Button type="button" variant="outlined" color="primary">
                                Cancel
                            </Button>
                        </Link>
                        <Button type="submit" variant="contained" color="primary" disabled={!allowSubmit} onClick={handleSubmit}>
                            Upload Profiles
                        </Button>
                    </Stack>
                </Container>
            </AppBar>
        </>
    )
}

export default MultiUpload;