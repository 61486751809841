import * as XLSX from 'xlsx';

export const readExcel = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}

export const downloadExcel = (data,fileName='downloadExcel.xlsx') => {
    var worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "invalid");
    XLSX.writeFile(workbook, fileName);
};

export const hasRequiredKeys = (obj, requiredKeys) => {
    for (let key of requiredKeys) {
        if (!(key in obj)) {
            return false;
        }
    }
    return true;
}