import {
    Avatar,
    Box,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import moment from 'moment';
import { ArrowSquareOut, CalendarBlank, Chat, Share, ThumbsUp } from 'phosphor-react';
import React from 'react';
import REACTION_MAP from '../constent/reactionMap';

const PostDetailPopUp = ({
    open,
    onClose,
    postData,
}) => {
    const {
        // contentLength,
        imageURL,
        likesCount,
        commentsCount,
        sharesCount,
        content,
        url,
        reactionTypeCounts,
        publishedDate,
        userId,
        videoURL
    } = postData;

    const {
        fullName,
        username,
        profileUrl,
        image,
        description,
        lastScraped
    } = userId;

    const textSecondary = '#6E6E77';

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <Box sx={{ position: 'absolute', top: 1, right: 1 }}>
                <IconButton href={url} target='_blank'>
                    <ArrowSquareOut size={24} />
                </IconButton>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} style={{ backgroundColor: '#F4F4F5' }}>
                    <Box p={4}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Avatar component={'a'} href={profileUrl} target='_blank' src={image} alt={username} sx={{ width: 64, height: 64 }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">{fullName}</Typography>
                                <Typography variant="body2" color={textSecondary}>@{username}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Stack direction={'row'} gap={1} alignItems="center">
                            <CalendarBlank size={16} color={textSecondary} />
                            <Typography variant="body2" fontWeight={500} color={textSecondary}>
                                Last scraped on {moment(lastScraped).format('MMM D, YYYY')}
                            </Typography>
                        </Stack>
                        <Typography variant="body2" fontWeight={500} color={textSecondary} sx={{ mt: 2 }}>
                            {description}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box p={4} mt={2}>
                        <Typography variant='body1' mb={3} sx={{ whiteSpace: 'pre-wrap' }}>
                            {content}
                        </Typography>
                        {imageURL.length > 0 && (
                            <Stack direction={'row'} flexWrap={'wrap'} mb={3}>
                                {
                                    imageURL.map((url, index) => (
                                        <Box key={index} sx={{ border: '1.5px solid white', width: '50%', height: '200px' }}>
                                            <img src={url} alt='post-image' style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                        </Box>
                                    ))
                                }
                            </Stack>
                        )}

                        {videoURL && (
                            <Box mb={3}>
                                <video src={videoURL} controls style={{ width: '100%', height: '350px' }} />
                            </Box>
                        )}

                        <Stack direction={'row'} gap={1.5}>
                            {reactionTypeCounts.map((reaction) => (
                                <Stack direction={'column'} alignItems={'center'} gap={1} key={reaction._id}>
                                    <Box width={28} height={28} position={'relative'} sx={{
                                        svg: {
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%'
                                        }
                                    }}>
                                        {REACTION_MAP[reaction.reactionType]}
                                    </Box>
                                    <Typography variant="body2" color="textSecondary" >
                                        {reaction.count} {reaction.reactionType.toLowerCase()}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                        <Divider sx={{ mt: 3 }} />
                        <Stack direction={'row'} mt={2} justifyContent={'space-between'} >
                            <Stack direction={'row'} gap={1} alignItems="center">
                                <ThumbsUp size={18} color={textSecondary} />
                                <Typography variant="body2" color={textSecondary}>{likesCount} Likes</Typography>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems="center">
                                <Share size={18} color={textSecondary} />
                                <Typography variant="body2" color={textSecondary}>{sharesCount} Shares</Typography>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems="center">
                                <Chat size={18} color={textSecondary} />
                                <Typography variant="body2" color={textSecondary}>{commentsCount} Comments</Typography>
                            </Stack>
                            <Stack direction={'row'} gap={1} alignItems="center">
                                <CalendarBlank size={18} color={textSecondary} />
                                <Typography variant="body2" color={textSecondary}>{moment(publishedDate).format('MMM D, YYYY')} </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>

        </Dialog>
    );
};

export default PostDetailPopUp;
