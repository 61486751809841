import { AppBar, Box, Breadcrumbs, Button, Container, Grid, Stack, styled, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../../layout/Header';
import { editFounderPost } from '../../../services/founderPost.service';

const StyledGrid = styled(Grid)(({ theme }) => ({
    background: 'white',
    padding: 32,
    borderRadius: 32,
}));

const Label = ({ label, required }) => {
    return (
        <Typography variant='subtitle2' mb={0.5}>
            {label} {required && <span style={{ color: 'tomato' }}>*</span>}
        </Typography>
    )
}

const EditFounderPost = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { mutate, isPending } = useMutation({
        mutationFn: editFounderPost,
        onSuccess: () => {
            toast.success('Edit Post successfully!');
            handleGoBack();
        },
        onError: (err) => {
            toast.error(err.message || 'Failed to edit Post');
        }
    })

    const [formData, setFormData] = useState({
        content: state?.content || '',
        likes: state?.likes || 0,
        comments: state?.comments || 0,
        linkedin_url: state?.linkedin_url || '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formatData = {
            post_id: state.post_id,
            body: formData
        }
        mutate(formatData);
    };

    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        <>
            <Header />
            <Container >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4 }}>
                    <Box style={{ color: '#858585', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleGoBack} >
                        <Typography color="text.light" on>Back</Typography>
                    </Box>
                    <Typography color="text.main">Edit Post</Typography>
                </Breadcrumbs>
                <Box mt={3}>
                    <Typography variant="h6" fontWeight={600} color="textPrimary">
                        Edit Post
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        Craft compelling content sourced from various inspirations
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <form onSubmit={handleSubmit}>
                        <StyledGrid container mt={4}  >
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    Post Details
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    All fields are required. Please provide the following information.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box mb={2}>
                                    <Label label={'Content'} required={true} />
                                    <TextField required size='small' multiline rows={6} fullWidth name="content" variant="outlined" value={formData.content} onChange={handleChange} placeholder='Enter Content' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Likes'} required={true} />
                                    <TextField required fullWidth size='small' name="likes" variant="outlined" value={formData.likes} onChange={handleChange} type="number" placeholder='Enter number of likes' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Comments'} required={true} />
                                    <TextField required fullWidth size='small' name="comments" variant="outlined" value={formData.comments} onChange={handleChange} type="number" placeholder='Enter number of comments' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Template URL (Reference of inspiration)'} />
                                    <TextField fullWidth size='small' name="linkedin_url" variant="outlined" value={formData.linkedin_url} onChange={handleChange} type="url" placeholder='Enter URL' />
                                </Box>
                            </Grid>
                        </StyledGrid>

                        <Box height={100} />
                        <AppBar position="fixed" color="default" sx={{ top: 'auto', py: 2.5, bgcolor: 'white', bottom: 0 }}>
                            <Container>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Button onClick={handleGoBack} type="button" variant="outlined" color="primary">
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary" disabled={isPending}>
                                        Submit
                                    </Button>
                                </Stack>
                            </Container>
                        </AppBar>
                    </form>
                </Box>
            </Container>
        </>
    );
};

export default EditFounderPost;
