import { Box, Button, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { DotsThreeVertical, PencilSimple, Plus } from "phosphor-react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchBox from "../../../component/SearchBox";
import { fetchReleventProfiles } from "../../../services/releventProfile.service";
import { getLinkedInUsername } from "../../../utils/helper";

const ActionMenu = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigator = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        navigator('/founder/edit-profile', {
            state: row
        })
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <DotsThreeVertical />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}>
                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                        <PencilSimple size={20} color="#79767E" />
                        <Typography variant="body2" color={'text.light'} >Edit</Typography>
                    </Stack>
                </MenuItem>
            </Menu>
        </div>
    );
};
const columns = [
    { field: '_id', headerName: 'ID' },
    {
        field: 'name',
        headerName: 'Full name',
        flex: 1,
    },
    {
        field: 'company',
        headerName: 'Company',
        flex: 1,
    },
    {
        field: 'linkedin_url',
        headerName: 'LinkedIn',
        flex: 1,
        renderCell: ({ row }) => {
            const username = getLinkedInUsername(row.linkedin_url);

            if (!username) {
              return <></>;
            }
      
            return (
              <Link style={{ color: 'black' }} target="_blank" to={row.linkedin_url}>
                {username}
              </Link>
            );
        }
    },
    {
        headerName: 'Action',
        width: 120,
        renderCell: ({ row }) => <ActionMenu row={row} />,
    },
];


const ReleventProfile = () => {
    let { founder_id } = useParams();
    const [search, setSearch] = useState('');
    const [pagination, setPagination] = useState({
        pageSize: 10,
        page: 0
    })

    const query = {
        page: pagination.page + 1,
        limit: pagination.pageSize,
        search,
        founder_id
    }

    const { data, isLoading, } = useQuery({
        queryKey: ['releventProfile', query],
        queryFn: ({ queryKey }) => fetchReleventProfiles(queryKey[1])
    });

    const handleSearchChange = (value) => {
        setSearch(value);
    };

    return (
        <>
            <Box >
                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mt={4} mb={6}>
                    <Box>
                        <Typography variant="h5" color={'text.main'} fontWeight={600}>Relevant Profiles for Founders</Typography>
                        <Typography variant="body2" color={'text.light'}>Explore Profiles that Match Founders' Background and Skills</Typography>
                    </Box>
                    <Link to={'/founder/add-profile'} state={{ founder_id }}>
                        <Button variant="contained" size="large" endIcon={<Plus />}>Add New Profile</Button>
                    </Link>
                </Stack>
                <Stack mb={4}>
                    <SearchBox onSearchChange={handleSearchChange} isLoading={isLoading} />
                </Stack>
                <Box sx={{ height: '100vh', width: '100%',pb:10 }}>
                    <DataGrid
                        rowHeight={60}
                        rows={data?.releventProfile || []}
                        columns={columns}
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                            columns: {
                                columnVisibilityModel: {
                                    _id: false
                                }
                            }
                        }}
                        loading={isLoading}
                        paginationMode="server"
                        rowCount={data?.total || 0}
                        pageSizeOptions={[10, 25, 50]}
                        paginationModel={pagination}
                        onPaginationModelChange={setPagination}
                        getRowId={(row) => row._id}
                        pagination
                    />
                </Box>
            </Box>
        </>
    )
}

export default ReleventProfile;
