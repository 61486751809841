const FunnyIcon = () => {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <circle cx="11" cy="11" r="11" fill="#44bfd3" />
        <circle cx="11" cy="11" r="8" fill="#d5f9fe" stroke="#104e58" />
        <path
          d="M9.98,8.88c-1.26-.73-2.86-.42-3.75,.73l.38,.45,.22-.12c.92-.51,1.99-.67,3.02-.45l.21-.55-.08-.05Z"
          fill="#104e58"
        />
        <path
          d="M15.68,9.6c-.9-1.14-2.5-1.45-3.75-.73l-.08,.05h0l.21,.55c1.03-.22,2.1-.05,3.02,.45l.22,.12,.38-.45h0Z"
          fill="#104e58"
        />
        <path
          d="M8.32,6.43c-.58-.08-1.78,.18-2.39,1.11"
          fill="none"
          stroke="#104e58"
          strokeLinecap="round"
        />
        <path
          d="M13.5,6.43c.58-.08,1.78,.18,2.39,1.11"
          fill="none"
          stroke="#104e58"
          strokeLinecap="round"
        />
        <path
          d="M14.48,12H7.52c-.51,0-.85,.51-.65,.98,.58,1.36,1.68,3.02,4.13,3.02s3.54-1.66,4.13-3.02c.2-.46-.14-.98-.65-.98Z"
          fill="#2199ac"
        />
        <path
          d="M11,14c-1.52,0-3,1-2,1.5q1,.5,2,.5h0q1,0,2-.5c1-.5-.48-1.5-2-1.5Z"
          fill="#d5f9fe"
        />
        <path
          d="M14.72,11.5H7.28c-.67,0-1.17,.65-.94,1.31,.27,.79,.69,1.8,1.41,2.62,.73,.84,1.78,1.47,3.26,1.47s2.52-.63,3.26-1.47c.72-.82,1.14-1.83,1.41-2.62,.22-.66-.28-1.31-.94-1.31Zm-1.21,3.27c-.57,.65-1.36,1.13-2.51,1.13s-1.93-.47-2.51-1.13c-.58-.67-.95-1.52-1.21-2.27h7.42c-.26,.75-.62,1.61-1.21,2.27Z"
          fill="#104e58"
        />
      </svg>
    )
}
export default FunnyIcon;