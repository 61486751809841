import api from "../utils/api";

export const fetchMetaData = async ({ url }) => {
    try {
        const response = await api.get('/scrapper/meta-data', {
            params: { url }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};