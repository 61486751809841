import { Box, Breadcrumbs, Container, Stack, Typography } from '@mui/material';
import { User, Users } from 'phosphor-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../layout/Header';
import SegmentedButton from '../../../component/SegmentButton';
import SingleUpload from './singleUpload';
import MultiUpload from './multiUpload';

const AddLinkedInProfile = () => {

    const [tab, setTab] = useState(0);

    const handleTabChange = (index) => {
        setTab(index);
    }

    const tabList = [
        <Stack key={0} direction={'row'} alignItems={'center'} gap={1} ><User /> Single Profile</Stack>,
        <Stack key={1} direction={'row'} alignItems={'center'} gap={1} ><Users /> Multiple Profile</Stack>
    ]

    const componentList = [<SingleUpload key={0} />, <MultiUpload key={1} />]
    return (
        <>
            <Header />
            <Container >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4 }}>
                    <Link style={{ color: '#858585' }} to="/linkedin-profile">
                        Home
                    </Link>
                    <Typography color="text.primary">Add LinkedIn Profile</Typography>
                </Breadcrumbs>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box mt={3}>
                        <Typography variant="h6" fontWeight={600} color="textPrimary">
                            Add New LinkedIn Profile
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                            Provide your details to add a LinkedIn profile.
                        </Typography>
                    </Box>
                    <SegmentedButton
                        list={tabList}
                        activeIndex={tab}
                        handleChange={handleTabChange}
                    />
                </Stack>
                <Box mt={5}>
                    {componentList[tab]}
                </Box>
            </Container>
        </>
    );
};

export default AddLinkedInProfile;
