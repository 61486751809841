const countryCode =
[
    {
        "Country": "Afghanistan",
        "Alpha2 code": "AF",
        "label": "93",
        "value": "93"
    },
    {
        "Country": "Albania",
        "Alpha2 code": "AL",
        "label": "355",
        "value": "355"
    },
    {
        "Country": "Algeria",
        "Alpha2 code": "DZ",
        "label": "213",
        "value": "213"
    },
    {
        "Country": "American Samoa",
        "Alpha2 code": "AS",
        "label": "1684",
        "value": "1684"
    },
    {
        "Country": "Andorra",
        "Alpha2 code": "AD",
        "label": "376",
        "value": "376"
    },
    {
        "Country": "Angola",
        "Alpha2 code": "AO",
        "label": "244",
        "value": "244"
    },
    {
        "Country": "Anguilla",
        "Alpha2 code": "AI",
        "label": "1264",
        "value": "1264"
    },
    {
        "Country": "Antarctica",
        "Alpha2 code": "AQ",
        "label": "672",
        "value": "672"
    },
    {
        "Country": "Antigua and Barbuda",
        "Alpha2 code": "AG",
        "label": "1268",
        "value": "1268"
    },
    {
        "Country": "Argentina",
        "Alpha2 code": "AR",
        "label": "54",
        "value": "54"
    },
    {
        "Country": "Armenia",
        "Alpha2 code": "AM",
        "label": "374",
        "value": "374"
    },
    {
        "Country": "Aruba",
        "Alpha2 code": "AW",
        "label": "297",
        "value": "297"
    },
    {
        "Country": "Australia (new?)",
        "Alpha2 code": "AU",
        "label": "61",
        "value": "61"
    },
    {
        "Country": "Austria",
        "Alpha2 code": "AT",
        "label": "43",
        "value": "43"
    },
    {
        "Country": "Azerbaijan",
        "Alpha2 code": "AZ",
        "label": "994",
        "value": "994"
    },
    {
        "Country": "Bahamas (the)",
        "Alpha2 code": "BS",
        "label": "1242",
        "value": "1242"
    },
    {
        "Country": "Bahrain",
        "Alpha2 code": "BH",
        "label": "973",
        "value": "973"
    },
    {
        "Country": "Bangladesh",
        "Alpha2 code": "BD",
        "label": "880",
        "value": "880"
    },
    {
        "Country": "Barbados",
        "Alpha2 code": "BB",
        "label": "1246",
        "value": "1246"
    },
    {
        "Country": "Belarus",
        "Alpha2 code": "BY",
        "label": "375",
        "value": "375"
    },
    {
        "Country": "Belgium",
        "Alpha2 code": "BE",
        "label": "32",
        "value": "32"
    },
    {
        "Country": "Belize",
        "Alpha2 code": "BZ",
        "label": "501",
        "value": "501"
    },
    {
        "Country": "Benin",
        "Alpha2 code": "BJ",
        "label": "229",
        "value": "229"
    },
    {
        "Country": "Bermuda",
        "Alpha2 code": "BM",
        "label": "1441",
        "value": "1441"
    },
    {
        "Country": "Bhutan",
        "Alpha2 code": "BT",
        "label": "975",
        "value": "975"
    },
    {
        "Country": "Bolivia (Plurinational State)",
        "Alpha2 code": "BO",
        "label": "591",
        "value": "591"
    },
    {
        "Country": "BES \nBonaire, Sint Eustatius and Saba",
        "Alpha2 code": "BQ",
        "label": "599",
        "value": "599"
    },
    {
        "Country": "Bosnia and Herzegovina",
        "Alpha2 code": "BA",
        "label": "387",
        "value": "387"
    },
    {
        "Country": "Botswana",
        "Alpha2 code": "BW",
        "label": "267",
        "value": "267"
    },
    {
        "Country": "Bouvet Island",
        "Alpha2 code": "BV",
        "label": "47",
        "value": "47"
    },
    {
        "Country": "Brazil",
        "Alpha2 code": "BR",
        "label": "55",
        "value": "55"
    },
    {
        "Country": "British Indian Ocean Territory (the)",
        "Alpha2 code": "IO",
        "label": "246",
        "value": "246"
    },
    {
        "Country": "Brunei Darussalam",
        "Alpha2 code": "BN",
        "label": "673",
        "value": "673"
    },
    {
        "Country": "Bulgaria",
        "Alpha2 code": "BG",
        "label": "359",
        "value": "359"
    },
    {
        "Country": "Burkina Faso",
        "Alpha2 code": "BF",
        "label": "226",
        "value": "226"
    },
    {
        "Country": "Burundi",
        "Alpha2 code": "BI",
        "label": "257",
        "value": "257"
    },
    {
        "Country": "Cabo Verde",
        "Alpha2 code": "CV",
        "label": "238",
        "value": "238"
    },
    {
        "Country": "Cambodia",
        "Alpha2 code": "KH",
        "label": "855",
        "value": "855"
    },
    {
        "Country": "Cameroon",
        "Alpha2 code": "CM",
        "label": "237",
        "value": "237"
    },
    {
        "Country": "Canada",
        "Alpha2 code": "CA",
        "label": "1",
        "value": "1"
    },
    {
        "Country": "Cayman Islands (the)",
        "Alpha2 code": "KY",
        "label": "1345",
        "value": "1345"
    },
    {
        "Country": "Central African Republic (the)",
        "Alpha2 code": "CF",
        "label": "236",
        "value": "236"
    },
    {
        "Country": "Chad",
        "Alpha2 code": "TD",
        "label": "235",
        "value": "235"
    },
    {
        "Country": "Chile",
        "Alpha2 code": "CL",
        "label": "56",
        "value": "56"
    },
    {
        "Country": "China",
        "Alpha2 code": "CN",
        "label": "86",
        "value": "86"
    },
    {
        "Country": "Colombia",
        "Alpha2 code": "CO",
        "label": "57",
        "value": "57"
    },
    {
        "Country": "Comoros (the)",
        "Alpha2 code": "KM",
        "label": "269",
        "value": "269"
    },
    {
        "Country": "Congo (the Democratic Republic of the)",
        "Alpha2 code": "CD",
        "label": "243",
        "value": "243"
    },
    {
        "Country": "Congo (the)",
        "Alpha2 code": "CG",
        "label": "242",
        "value": "242"
    },
    {
        "Country": "Cook Islands (the)",
        "Alpha2 code": "CK",
        "label": "682",
        "value": "682"
    },
    {
        "Country": "Costa Rica",
        "Alpha2 code": "CR",
        "label": "506",
        "value": "506"
    },
    {
        "Country": "Croatia",
        "Alpha2 code": "HR",
        "label": "385",
        "value": "385"
    },
    {
        "Country": "Cuba",
        "Alpha2 code": "CU",
        "label": "53",
        "value": "53"
    },
    {
        "Country": "Cyprus",
        "Alpha2 code": "CY",
        "label": "357",
        "value": "357"
    },
    {
        "Country": "Czechia",
        "Alpha2 code": "CZ",
        "label": "420",
        "value": "420"
    },
    {
        "Country": "C�te d�Ivoire",
        "Alpha2 code": "CI",
        "label": "225",
        "value": "225"
    },
    {
        "Country": "Denmark",
        "Alpha2 code": "DK",
        "label": "45",
        "value": "45"
    },
    {
        "Country": "Djibouti",
        "Alpha2 code": "DJ",
        "label": "253",
        "value": "253"
    },
    {
        "Country": "Ecuador",
        "Alpha2 code": "EC",
        "label": "593",
        "value": "593"
    },
    {
        "Country": "Egypt",
        "Alpha2 code": "EG",
        "label": "20",
        "value": "20"
    },
    {
        "Country": "El Salvador",
        "Alpha2 code": "SV",
        "label": "503",
        "value": "503"
    },
    {
        "Country": "Equatorial Guinea",
        "Alpha2 code": "GQ",
        "label": "240",
        "value": "240"
    },
    {
        "Country": "Eritrea",
        "Alpha2 code": "ER",
        "label": "291",
        "value": "291"
    },
    {
        "Country": "Estonia",
        "Alpha2 code": "EE",
        "label": "372",
        "value": "372"
    },
    {
        "Country": "Eswatini",
        "Alpha2 code": "SZ",
        "label": "268",
        "value": "268"
    },
    {
        "Country": "Ethiopia",
        "Alpha2 code": "ET",
        "label": "251",
        "value": "251"
    },
    {
        "Country": "Falkland Islands (the) [Malvinas]",
        "Alpha2 code": "FK",
        "label": "500",
        "value": "500"
    },
    {
        "Country": "Faroe Islands (the)",
        "Alpha2 code": "FO",
        "label": "298",
        "value": "298"
    },
    {
        "Country": "Fiji",
        "Alpha2 code": "FJ",
        "label": "679",
        "value": "679"
    },
    {
        "Country": "Finland",
        "Alpha2 code": "FI",
        "label": "358",
        "value": "358"
    },
    {
        "Country": "France",
        "Alpha2 code": "FR",
        "label": "33",
        "value": "33"
    },
    {
        "Country": "French Guiana",
        "Alpha2 code": "GF",
        "label": "594",
        "value": "594"
    },
    {
        "Country": "French Polynesia",
        "Alpha2 code": "PF",
        "label": "689",
        "value": "689"
    },
    {
        "Country": "French Southern Territories (the)",
        "Alpha2 code": "TF",
        "label": "262",
        "value": "262"
    },
    {
        "Country": "Gabon",
        "Alpha2 code": "GA",
        "label": "241",
        "value": "241"
    },
    {
        "Country": "Gambia (the)",
        "Alpha2 code": "GM",
        "label": "220",
        "value": "220"
    },
    {
        "Country": "Georgia",
        "Alpha2 code": "GE",
        "label": "995",
        "value": "995"
    },
    {
        "Country": "Germany",
        "Alpha2 code": "DE",
        "label": "49",
        "value": "49"
    },
    {
        "Country": "Ghana",
        "Alpha2 code": "GH",
        "label": "233",
        "value": "233"
    },
    {
        "Country": "Gibraltar",
        "Alpha2 code": "GI",
        "label": "350",
        "value": "350"
    },
    {
        "Country": "Greece",
        "Alpha2 code": "GR",
        "label": "30",
        "value": "30"
    },
    {
        "Country": "Greenland",
        "Alpha2 code": "GL",
        "label": "299",
        "value": "299"
    },
    {
        "Country": "Grenada",
        "Alpha2 code": "GD",
        "label": "1473",
        "value": "1473"
    },
    {
        "Country": "Guadeloupe",
        "Alpha2 code": "GP",
        "label": "590",
        "value": "590"
    },
    {
        "Country": "Guam",
        "Alpha2 code": "GU",
        "label": "1671",
        "value": "1671"
    },
    {
        "Country": "Guatemala",
        "Alpha2 code": "GT",
        "label": "502",
        "value": "502"
    },
    {
        "Country": "Guernsey",
        "Alpha2 code": "GG",
        "label": "441481",
        "value": "441481"
    },
    {
        "Country": "Guinea",
        "Alpha2 code": "GN",
        "label": "224",
        "value": "224"
    },
    {
        "Country": "GuineaBissau",
        "Alpha2 code": "GW",
        "label": "245",
        "value": "245"
    },
    {
        "Country": "Guyana",
        "Alpha2 code": "GY",
        "label": "592",
        "value": "592"
    },
    {
        "Country": "Haiti",
        "Alpha2 code": "HT",
        "label": "509",
        "value": "509"
    },
    {
        "Country": "Holy See (the)",
        "Alpha2 code": "VA",
        "label": "379",
        "value": "379"
    },
    {
        "Country": "Honduras",
        "Alpha2 code": "HN",
        "label": "504",
        "value": "504"
    },
    {
        "Country": "Hong Kong",
        "Alpha2 code": "HK",
        "label": "852",
        "value": "852"
    },
    {
        "Country": "Hungary",
        "Alpha2 code": "HU",
        "label": "36",
        "value": "36"
    },
    {
        "Country": "Iceland",
        "Alpha2 code": "IS",
        "label": "354",
        "value": "354"
    },
    {
        "Country": "India",
        "Alpha2 code": "IN",
        "label": "91",
        "value": "91"
    },
    {
        "Country": "Indonesia",
        "Alpha2 code": "ID",
        "label": "62",
        "value": "62"
    },
    {
        "Country": "Iran (Islamic Republic of)",
        "Alpha2 code": "IR",
        "label": "98",
        "value": "98"
    },
    {
        "Country": "Iraq",
        "Alpha2 code": "IQ",
        "label": "964",
        "value": "964"
    },
    {
        "Country": "Ireland",
        "Alpha2 code": "IE",
        "label": "353",
        "value": "353"
    },
    {
        "Country": "Isle of Man",
        "Alpha2 code": "IM",
        "label": "44",
        "value": "44"
    },
    {
        "Country": "Israel",
        "Alpha2 code": "IL",
        "label": "972",
        "value": "972"
    },
    {
        "Country": "Italy",
        "Alpha2 code": "IT",
        "label": "39",
        "value": "39"
    },
    {
        "Country": "Jamaica",
        "Alpha2 code": "JM",
        "label": "1876",
        "value": "1876"
    },
    {
        "Country": "Japan",
        "Alpha2 code": "JP",
        "label": "81",
        "value": "81"
    },
    {
        "Country": "Jersey",
        "Alpha2 code": "JE",
        "label": "441534",
        "value": "441534"
    },
    {
        "Country": "Jordan",
        "Alpha2 code": "JO",
        "label": "962",
        "value": "962"
    },
    {
        "Country": "Kazakhstan",
        "Alpha2 code": "KZ",
        "label": "7",
        "value": "7"
    },
    {
        "Country": "Kenya",
        "Alpha2 code": "KE",
        "label": "254",
        "value": "254"
    },
    {
        "Country": "Kiribati",
        "Alpha2 code": "KI",
        "label": "686",
        "value": "686"
    },
    {
        "Country": "Korea (the Democratic People�s Republic of) \nNorth Korea",
        "Alpha2 code": "KP",
        "label": "850",
        "value": "850"
    },
    {
        "Country": "Korea (the Republic of)",
        "Alpha2 code": "KR",
        "label": "82",
        "value": "82"
    },
    {
        "Country": "Kuwait",
        "Alpha2 code": "KW",
        "label": "965",
        "value": "965"
    },
    {
        "Country": "Kyrgyzstan",
        "Alpha2 code": "KG",
        "label": "996",
        "value": "996"
    },
    {
        "Country": "Lao People�s Democratic Republic (the)",
        "Alpha2 code": "LA",
        "label": "856",
        "value": "856"
    },
    {
        "Country": "Latvia",
        "Alpha2 code": "LV",
        "label": "371",
        "value": "371"
    },
    {
        "Country": "Lebanon",
        "Alpha2 code": "LB",
        "label": "961",
        "value": "961"
    },
    {
        "Country": "Lesotho",
        "Alpha2 code": "LS",
        "label": "266",
        "value": "266"
    },
    {
        "Country": "Liberia",
        "Alpha2 code": "LR",
        "label": "231",
        "value": "231"
    },
    {
        "Country": "Libya",
        "Alpha2 code": "LY",
        "label": "218",
        "value": "218"
    },
    {
        "Country": "Liechtenstein",
        "Alpha2 code": "LI",
        "label": "423",
        "value": "423"
    },
    {
        "Country": "Lithuania",
        "Alpha2 code": "LT",
        "label": "370",
        "value": "370"
    },
    {
        "Country": "Luxembourg",
        "Alpha2 code": "LU",
        "label": "352",
        "value": "352"
    },
    {
        "Country": "Macao",
        "Alpha2 code": "MO",
        "label": "853",
        "value": "853"
    },
    {
        "Country": "Madagascar",
        "Alpha2 code": "MG",
        "label": "261",
        "value": "261"
    },
    {
        "Country": "Malawi",
        "Alpha2 code": "MW",
        "label": "265",
        "value": "265"
    },
    {
        "Country": "Malaysia",
        "Alpha2 code": "MY",
        "label": "60",
        "value": "60"
    },
    {
        "Country": "Maldives",
        "Alpha2 code": "MV",
        "label": "960",
        "value": "960"
    },
    {
        "Country": "Mali",
        "Alpha2 code": "ML",
        "label": "223",
        "value": "223"
    },
    {
        "Country": "Malta",
        "Alpha2 code": "MT",
        "label": "356",
        "value": "356"
    },
    {
        "Country": "Marshall Islands (the)",
        "Alpha2 code": "MH",
        "label": "692",
        "value": "692"
    },
    {
        "Country": "Martinique",
        "Alpha2 code": "MQ",
        "label": "596",
        "value": "596"
    },
    {
        "Country": "Mauritania",
        "Alpha2 code": "MR",
        "label": "222",
        "value": "222"
    },
    {
        "Country": "Mauritius",
        "Alpha2 code": "MU",
        "label": "230",
        "value": "230"
    },
    {
        "Country": "Mexico",
        "Alpha2 code": "MX",
        "label": "52",
        "value": "52"
    },
    {
        "Country": "Micronesia (Federated States of)",
        "Alpha2 code": "FM",
        "label": "691",
        "value": "691"
    },
    {
        "Country": "Moldova (the Republic of)",
        "Alpha2 code": "MD",
        "label": "373",
        "value": "373"
    },
    {
        "Country": "Monaco",
        "Alpha2 code": "MC",
        "label": "377",
        "value": "377"
    },
    {
        "Country": "Mongolia",
        "Alpha2 code": "MN",
        "label": "976",
        "value": "976"
    },
    {
        "Country": "Montenegro",
        "Alpha2 code": "ME",
        "label": "382",
        "value": "382"
    },
    {
        "Country": "Montserrat",
        "Alpha2 code": "MS",
        "label": "1664",
        "value": "1664"
    },
    {
        "Country": "Morocco",
        "Alpha2 code": "MA",
        "label": "212",
        "value": "212"
    },
    {
        "Country": "Mozambique",
        "Alpha2 code": "MZ",
        "label": "258",
        "value": "258"
    },
    {
        "Country": "Myanmar",
        "Alpha2 code": "MM",
        "label": "95",
        "value": "95"
    },
    {
        "Country": "Namibia",
        "Alpha2 code": "NA",
        "label": "264",
        "value": "264"
    },
    {
        "Country": "Nauru",
        "Alpha2 code": "NR",
        "label": "674",
        "value": "674"
    },
    {
        "Country": "Nepal",
        "Alpha2 code": "NP",
        "label": "977",
        "value": "977"
    },
    {
        "Country": "Netherlands (the)",
        "Alpha2 code": "NL",
        "label": "31",
        "value": "31"
    },
    {
        "Country": "New Caledonia",
        "Alpha2 code": "NC",
        "label": "687",
        "value": "687"
    },
    {
        "Country": "New Zealand",
        "Alpha2 code": "NZ",
        "label": "64",
        "value": "64"
    },
    {
        "Country": "Nicaragua",
        "Alpha2 code": "NI",
        "label": "505",
        "value": "505"
    },
    {
        "Country": "Niger (the)",
        "Alpha2 code": "NE",
        "label": "227",
        "value": "227"
    },
    {
        "Country": "Nigeria",
        "Alpha2 code": "NG",
        "label": "234",
        "value": "234"
    },
    {
        "Country": "Niue",
        "Alpha2 code": "NU",
        "label": "683",
        "value": "683"
    },
    {
        "Country": "Northern Mariana Islands (the)",
        "Alpha2 code": "MP",
        "label": "1670",
        "value": "1670"
    },
    {
        "Country": "Oman",
        "Alpha2 code": "OM",
        "label": "968",
        "value": "968"
    },
    {
        "Country": "Pakistan",
        "Alpha2 code": "PK",
        "label": "92",
        "value": "92"
    },
    {
        "Country": "Palau",
        "Alpha2 code": "PW",
        "label": "680",
        "value": "680"
    },
    {
        "Country": "Palestine, State of",
        "Alpha2 code": "PS",
        "label": "970",
        "value": "970"
    },
    {
        "Country": "Panama",
        "Alpha2 code": "PA",
        "label": "507",
        "value": "507"
    },
    {
        "Country": "Papua New Guinea",
        "Alpha2 code": "PG",
        "label": "675",
        "value": "675"
    },
    {
        "Country": "Paraguay",
        "Alpha2 code": "PY",
        "label": "595",
        "value": "595"
    },
    {
        "Country": "Peru",
        "Alpha2 code": "PE",
        "label": "51",
        "value": "51"
    },
    {
        "Country": "Philippines (the)",
        "Alpha2 code": "PH",
        "label": "63",
        "value": "63"
    },
    {
        "Country": "Poland",
        "Alpha2 code": "PL",
        "label": "48",
        "value": "48"
    },
    {
        "Country": "Portugal",
        "Alpha2 code": "PT",
        "label": "351",
        "value": "351"
    },
    {
        "Country": "Qatar",
        "Alpha2 code": "QA",
        "label": "974",
        "value": "974"
    },
    {
        "Country": "Republic of North Macedonia",
        "Alpha2 code": "MK",
        "label": "389",
        "value": "389"
    },
    {
        "Country": "Romania",
        "Alpha2 code": "RO",
        "label": "40",
        "value": "40"
    },
    {
        "Country": "Rwanda",
        "Alpha2 code": "RW",
        "label": "250",
        "value": "250"
    },
    {
        "Country": "Saint Helena, Ascension and Tristan da Cunha",
        "Alpha2 code": "SH",
        "label": "290",
        "value": "290"
    },
    {
        "Country": "Saint Kitts and Nevis",
        "Alpha2 code": "KN",
        "label": "1869",
        "value": "1869"
    },
    {
        "Country": "Saint Lucia",
        "Alpha2 code": "LC",
        "label": "1758",
        "value": "1758"
    },
    {
        "Country": "Saint Martin (French part)",
        "Alpha2 code": "MF",
        "label": "721",
        "value": "721"
    },
    {
        "Country": "Saint Pierre and Miquelon",
        "Alpha2 code": "PM",
        "label": "508",
        "value": "508"
    },
    {
        "Country": "Saint Vincent and the Grenadines",
        "Alpha2 code": "VC",
        "label": "1784",
        "value": "1784"
    },
    {
        "Country": "Samoa",
        "Alpha2 code": "WS",
        "label": "685",
        "value": "685"
    },
    {
        "Country": "San Marino",
        "Alpha2 code": "SM",
        "label": "378",
        "value": "378"
    },
    {
        "Country": "Sao Tome and Principe",
        "Alpha2 code": "ST",
        "label": "239",
        "value": "239"
    },
    {
        "Country": "Saudi Arabia",
        "Alpha2 code": "SA",
        "label": "966",
        "value": "966"
    },
    {
        "Country": "Senegal",
        "Alpha2 code": "SN",
        "label": "221",
        "value": "221"
    },
    {
        "Country": "Serbia",
        "Alpha2 code": "RS",
        "label": "381",
        "value": "381"
    },
    {
        "Country": "Seychelles",
        "Alpha2 code": "SC",
        "label": "248",
        "value": "248"
    },
    {
        "Country": "Sierra Leone",
        "Alpha2 code": "SL",
        "label": "232",
        "value": "232"
    },
    {
        "Country": "Singapore",
        "Alpha2 code": "SG",
        "label": "65",
        "value": "65"
    },
    {
        "Country": "Slovakia",
        "Alpha2 code": "SK",
        "label": "421",
        "value": "421"
    },
    {
        "Country": "Slovenia",
        "Alpha2 code": "SI",
        "label": "386",
        "value": "386"
    },
    {
        "Country": "Solomon Islands",
        "Alpha2 code": "SB",
        "label": "677",
        "value": "677"
    },
    {
        "Country": "Somalia",
        "Alpha2 code": "SO",
        "label": "252",
        "value": "252"
    },
    {
        "Country": "South Africa",
        "Alpha2 code": "ZA",
        "label": "27",
        "value": "27"
    },
    {
        "Country": "South Sudan",
        "Alpha2 code": "SS",
        "label": "211",
        "value": "211"
    },
    {
        "Country": "Spain",
        "Alpha2 code": "ES",
        "label": "34",
        "value": "34"
    },
    {
        "Country": "Sri Lanka",
        "Alpha2 code": "LK",
        "label": "94",
        "value": "94"
    },
    {
        "Country": "Sudan (the)",
        "Alpha2 code": "SD",
        "label": "249",
        "value": "249"
    },
    {
        "Country": "Suriname",
        "Alpha2 code": "SR",
        "label": "597",
        "value": "597"
    },
    {
        "Country": "Sweden",
        "Alpha2 code": "SE",
        "label": "46",
        "value": "46"
    },
    {
        "Country": "Switzerland",
        "Alpha2 code": "CH",
        "label": "41",
        "value": "41"
    },
    {
        "Country": "Syrian Arab Republic",
        "Alpha2 code": "SY",
        "label": "963",
        "value": "963"
    },
    {
        "Country": "Taiwan (Province of China)",
        "Alpha2 code": "TW",
        "label": "886",
        "value": "886"
    },
    {
        "Country": "Tajikistan",
        "Alpha2 code": "TJ",
        "label": "992",
        "value": "992"
    },
    {
        "Country": "Tanzania, United Republic of",
        "Alpha2 code": "TZ",
        "label": "255",
        "value": "255"
    },
    {
        "Country": "Thailand",
        "Alpha2 code": "TH",
        "label": "66",
        "value": "66"
    },
    {
        "Country": "TimorLeste",
        "Alpha2 code": "TL",
        "label": "670",
        "value": "670"
    },
    {
        "Country": "Togo",
        "Alpha2 code": "TG",
        "label": "228",
        "value": "228"
    },
    {
        "Country": "Tokelau",
        "Alpha2 code": "TK",
        "label": "690",
        "value": "690"
    },
    {
        "Country": "Tonga",
        "Alpha2 code": "TO",
        "label": "676",
        "value": "676"
    },
    {
        "Country": "Trinidad and Tobago",
        "Alpha2 code": "TT",
        "label": "1868",
        "value": "1868"
    },
    {
        "Country": "Tunisia",
        "Alpha2 code": "TN",
        "label": "216",
        "value": "216"
    },
    {
        "Country": "Turkey",
        "Alpha2 code": "TR",
        "label": "90",
        "value": "90"
    },
    {
        "Country": "Turkmenistan",
        "Alpha2 code": "TM",
        "label": "993",
        "value": "993"
    },
    {
        "Country": "Turks and Caicos Islands (the)",
        "Alpha2 code": "TC",
        "label": "1649",
        "value": "1649"
    },
    {
        "Country": "Tuvalu",
        "Alpha2 code": "TV",
        "label": "688",
        "value": "688"
    },
    {
        "Country": "Uganda",
        "Alpha2 code": "UG",
        "label": "256",
        "value": "256"
    },
    {
        "Country": "Ukraine",
        "Alpha2 code": "UA",
        "label": "380",
        "value": "380"
    },
    {
        "Country": "United Arab Emirates (the)",
        "Alpha2 code": "AE",
        "label": "971",
        "value": "971"
    },
    {
        "Country": "Uruguay",
        "Alpha2 code": "UY",
        "label": "598",
        "value": "598"
    },
    {
        "Country": "Uzbekistan",
        "Alpha2 code": "UZ",
        "label": "998",
        "value": "998"
    },
    {
        "Country": "Vanuatu",
        "Alpha2 code": "VU",
        "label": "678",
        "value": "678"
    },
    {
        "Country": "Venezuela (Bolivarian Republic of)",
        "Alpha2 code": "VE",
        "label": "58",
        "value": "58"
    },
    {
        "Country": "Vietnam",
        "Alpha2 code": "VN",
        "label": "84",
        "value": "84"
    },
    {
        "Country": "Virgin Islands (British)",
        "Alpha2 code": "VG",
        "label": "1284",
        "value": "1284"
    },
    {
        "Country": "Virgin Islands (U.S.)",
        "Alpha2 code": "VI",
        "label": "1340",
        "value": "1340"
    },
    {
        "Country": "Wallis and Futuna",
        "Alpha2 code": "WF",
        "label": "681",
        "value": "681"
    },
    {
        "Country": "Yemen",
        "Alpha2 code": "YE",
        "label": "967",
        "value": "967"
    }
]

export default countryCode