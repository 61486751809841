import { Autocomplete, Avatar, CircularProgress, InputAdornment, TextField, Typography, styled } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { User } from "phosphor-react";
import { useMemo, useState } from "react";
import { fetchLinkedInUsers } from "../services/linkedin.service";

const CustomAutocomplete = styled(Autocomplete)(() => ({
    '& .MuiFormControl-root': {
        background: 'transparent',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '26px',
        background: 'white'
    },
    '& .MuiInputBase-input::placeholder': {
        opacity: 1,
        fontSize: 14
    }
}));

const LinkedInUserSelect = ({ value = {}, handleChange }) => {
    const [search, setSearch] = useState(value?.fullName || '');

    const query = useMemo(() => ({
        page: 1,
        limit: 10,
        search,
    }), [search]);

    const { data, isLoading } = useQuery({
        queryKey: ['linkedin-users', query],
        queryFn: ({ queryKey }) => fetchLinkedInUsers(queryKey[1]),
    });

    return (
        <CustomAutocomplete
            value={value}
            options={data?.data || []}
            getOptionLabel={(option) => option.fullName || ''}
            loading={isLoading}
            onInputChange={(event, newInputValue) => {
                setSearch(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            onChange={(event, newValue) => {
                handleChange(newValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Author"
                    sx={{
                        minWidth: '200px',
                        fontSize: '14px'
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <User size={23} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option._id}>
                    <Avatar
                        src={option.image}
                        alt={option.fullName}
                        sx={{ mr: 1 }}
                    />
                    <Typography whiteSpace={'nowrap'} textTransform={'capitalize'}>
                        {option.fullName}
                    </Typography>
                </li>
            )}
        />
    );
}

export default LinkedInUserSelect;
