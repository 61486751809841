const CelebrateIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" id="praise-creation-large" data-supported-dps="48x48">
            <g>
                <path fill="none" d="M0 0h48v48H0z" />
                <path d="M39.33 30.67l-1.6-1.2s-.66-6.35-1.82-7.58c-2.09-2.2-3.42-5.42-4.3-8.46-.55-1.88-.94-2.55-2.63-2.58a2.57 2.57 0 00-2.31 2.84 21.76 21.76 0 00.23 2.55 34.79 34.79 0 002 6.54l-.65-.39-15.44-11.74c-1.15-.87-2.81-1.26-3.93.24s-.22 2.89.93 3.75l8 6 2.39 1.81-12.79-9.61c-1.15-.87-2.81-1.26-3.94.24s-.21 2.92.93 3.75l8 6 4.8 3.6-10.4-7.79c-1.15-.87-2.81-1.26-3.94.24s-.21 2.89.94 3.75l8 6 4 3-8-6c-1.15-.87-2.78-1.29-3.86.14s-.29 3 .86 3.86l14.54 11a9.77 9.77 0 007.41 1.8c.81.59 2.4 1.8 2.4 1.8a27.46 27.46 0 005.59-5.8 41 41 0 004.59-7.76z" fill="#49992a" />
                <path d="M29.73 23.48l-2.41-1.81" fill="#93d870" />
                <path d="M39.21 31l.12-.28" fill="none" stroke="#43722b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path d="M37.6 28.4c-.25-1.87-.41-7.47-1.27-8.38-2.09-2.21-3.84-3.55-4.72-6.59-.55-1.88-.94-2.55-2.63-2.58a2.57 2.57 0 00-2.31 2.84 21.76 21.76 0 00.23 2.55 43.79 43.79 0 002 6.92L12.81 11c-1.15-.86-2.81-1.25-3.93.25s-.22 2.88.93 3.75l8 6 2.39 1.8-12.79-9.59c-1.15-.86-2.81-1.25-3.94.25s-.21 2.88.93 3.75l8 6 4.8 3.61L6.8 19c-1.15-.86-2.81-1.25-3.94.25s-.21 2.89.94 3.75l8 6 4 3-8-6c-1.15-.86-2.78-1.29-3.86.15s-.29 3 .86 3.85l14.53 10.68a9.77 9.77 0 007.41 1.8" fill="none" stroke="#165209" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path d="M29.73 23.48l-2.41-1.81" fill="none" stroke="#43722b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path d="M45.93 31.87l-1.6-1.2s-.66-6.36-1.82-7.58a21 21 0 01-4.3-8.46c-.55-1.88-.94-2.55-2.63-2.58a2.56 2.56 0 00-2.31 2.83 21.76 21.76 0 00.23 2.55c.63 3.3 1.31 6 1.39 6.16l-15.24-11.4c-1.15-.87-2.81-1.26-3.94.24s-.22 2.89.93 3.75l8 6L27 24l-12.76-9.62c-1.15-.86-2.81-1.25-3.93.25s-.22 2.88.93 3.75l8 6L24 28l-10.36-7.82c-1.15-.87-2.82-1.25-3.94.24s-.22 2.89.93 3.76l8 6 4 3-8-6c-1.15-.86-2.79-1.29-3.87.15s-.29 3 .86 3.85l14.31 10.7c2.53 1.87 7.07 2.12 7 2.12a21.45 21.45 0 002.81 1.47A11.38 11.38 0 0042.93 41a17 17 0 003-9.13z" fill="#6dae4f" />
                <path d="M36.33 24.67l-2.41-1.8" fill="#93d870" />
                <path d="M12 1.65l.79 3.52M23.2 6l-2.94 2M18.68 1l-2.15 5.6M45.77 31.88a7.5 7.5 0 01-1.62-2.66c-.18-.75-.23-1.53-.37-2.29-.22-1.17-.41-2.93-1.27-3.84a21 21 0 01-4.3-8.46c-.55-1.88-.94-2.55-2.63-2.58a2.56 2.56 0 00-2.31 2.83 21.76 21.76 0 00.23 2.55c.63 3.3 1.31 6 1.39 6.16l-15.24-11.4c-1.15-.87-2.81-1.26-3.94.24s-.22 2.89.93 3.75l8 6L27 24l-12.76-9.62c-1.15-.86-2.81-1.25-3.93.25s-.22 2.88.93 3.75l8 6L24 28l-10.36-7.82c-1.15-.87-2.82-1.25-3.94.24s-.22 2.89.93 3.76l8 6 4 3-8-6c-1.15-.86-2.79-1.29-3.87.15s-.29 3 .86 3.85l14.31 10.7a9.65 9.65 0 004 1.75c.64.12 1.32.18 2.07.32a9.93 9.93 0 013.08 1 3 3 0 002.15.17 13.23 13.23 0 006.26-4.59 12.75 12.75 0 002.48-7 2.92 2.92 0 00-.2-1.65z" fill="none" stroke="#165209" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </svg>

    )
}

export default CelebrateIcon;