import { Box, Grid, Stack, Typography } from "@mui/material";
import { useQuery } from '@tanstack/react-query';
import NewsCard from "../../../component/NewsCard";
import { fetchReleventNews } from "../../../services/releventNews.service";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../../component/LoadingScreen";


const ReleventNews = () => {
    let { founder_id } = useParams();
    const query = {
        founder_id
    };

    const { data, isLoading } = useQuery({
        queryKey: ['relevantNews', query],
        queryFn: ({ queryKey }) => fetchReleventNews(queryKey[1])
    });

    return (
        <Box>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mt={4} >
                <Box>
                    <Typography variant="h5" color={'text.main'} fontWeight={600}>Founder Relevent News</Typography>
                    <Typography variant="body2" color={'text.light'}>  Discover the most recent trends and insights.
                    </Typography>
                </Box>
            </Stack>
            <Grid container spacing={3} py={4}>
                {isLoading ? (
                    <LoadingScreen/>
                ) : (
                    data?.news?.map((newsItem) => (
                        <Grid item xs={12} sm={6} md={4} key={newsItem.id}>
                            <NewsCard
                                title={newsItem.title}
                                text={newsItem.text}
                                url={newsItem.url}
                                image={newsItem.image}
                                publish_date={newsItem.publish_date}
                                author={newsItem.author}
                            />
                        </Grid>
                    ))
                )}
            </Grid>
        </Box>
    );
};

export default ReleventNews;
