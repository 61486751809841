import { Box, Card, CardContent, IconButton, Menu, MenuItem, Stack, styled, Typography } from "@mui/material";
import { ChatText, DotsThreeVertical, LinkSimple, PencilSimple, ThumbsUp } from "phosphor-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import URLPreviewer from "./URLPreviewer";

const StyledStack = styled(Stack)(({ theme }) => ({
    color: theme.palette.text.main,
    textDecoration: 'none',
    '&:hover': {
        color: theme.palette.primary.main,
        '& svg path, & svg line': {
            stroke: theme.palette.primary.main
        },
    }
}));
const TemplateCardTool = ({ _id, likes = 0, comments = 0, content, linkedin_url }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        navigate('/founder/edit-template', {
            state: {
                likes,
                comments,
                content,
                linkedin_url,
                template_id: _id
            }
        })
    }
    return (
        <Box>
            <IconButton onClick={handleClick}>
                <DotsThreeVertical />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}>
                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                        <PencilSimple size={20} color="#79767E" />
                        <Typography variant="body2" color={'text.light'} >Edit</Typography>
                    </Stack>
                </MenuItem>
                {/* <MenuItem >
                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                        <Trash size={20} color="red" />
                        <Typography variant="body2" color={'text.light'} >Delete</Typography>
                    </Stack>
                </MenuItem> */}
            </Menu>
        </Box>
    )
}
const HighlightedText = ({ text, highlight }) => {
    if (!highlight.trim()) {
        return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, 'gi');
    const parts = text.split(regex);
    return (
        <span>
            {parts.filter(part => part).map((part, i) => (
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <mark key={i}>{part}</mark>
                ) : (
                    <span key={i}>{part}</span>
                )
            ))}
        </span>
    );
};
const TemplateCard = ({ _id, likes = 0, comments = 0, content, linkedin_url, hightlight }) => {
    return (
        <Card elevation={3} >
            <CardContent sx={{ px: 4, py: 4, position: 'relative' }}>
                <Box position={'absolute'} right={'12px'} top={'12px'}>
                    <TemplateCardTool {...{ _id, likes, comments, content, linkedin_url }} />
                </Box>
                <Typography variant="body2" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
                    <HighlightedText text={content} highlight={hightlight} />
                </Typography>
                <Stack direction="row" alignItems="center" gap={1} mt={2}>
                    <ThumbsUp size={16} />
                    <Typography variant="caption">{likes} Likes</Typography>
                    <Typography variant="caption" sx={{ mx: 1 }}>•</Typography>
                    <ChatText size={16} />
                    <Typography variant="caption">{comments} comments</Typography>
                    {
                        linkedin_url && (
                            <>
                                <Typography variant="caption" sx={{ mx: 1 }}>•</Typography>
                                <URLPreviewer url={linkedin_url}>
                                    <StyledStack direction={'row'} target="_blank" to={linkedin_url} component={Link} alignItems={'center'} gap={1}>
                                        <LinkSimple size={16} color="black" />
                                        <Typography variant="caption" target="_blank" >Link</Typography>
                                    </StyledStack>
                                </URLPreviewer>
                            </>
                        )
                    }
                </Stack>

            </CardContent>
        </Card>
    );
}

export default TemplateCard;
