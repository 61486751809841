import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthGuard from './guard/authGuard';
import Login from './pages/auth/login';
import Founder from './pages/founder';
import FounderDetail from './pages/founder-detail';
import AddFounderTemplate from './pages/founder-detail/template-idea/addFounderTemplate';
import AddFounder from './pages/founder/addFounder';
import EditFounder from './pages/founder/editFounder';
import theme from './theme/theme';
import AddProfile from './pages/founder-detail/relevent-profile/add-profile';
import EditFounderTemplate from './pages/founder-detail/template-idea/editFounderTemplate';
import EditProfile from './pages/founder-detail/relevent-profile/edit-profile';
import AddFounderPost from './pages/founder-detail/founder-post/addFounderPost';
import EditFounderPost from './pages/founder-detail/founder-post/editFounderPost';
import Posts from './pages/posts';
import LinkedInProfile from './pages/linkedin-profile';
import EditLinkedInProfile from './pages/linkedin-profile/editLinkedInProfile';
import AddLinkedInProfile from './pages/linkedin-profile/add-linkedin-profile';
import AddTopic from './pages/hot-topic/addTopic';
const queryClient = new QueryClient()

function App() {
  return (
    <div className="App" style={{ minHeight: '100vh', background: '#F6F6F6' }}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<AuthGuard><Founder /></AuthGuard>} />
              <Route path="/add-founder" element={<AuthGuard><AddFounder /></AuthGuard>} />
              <Route path="/edit-founder" element={<AuthGuard><EditFounder /></AuthGuard>} />
              <Route path="/founders/:founder_id" element={<AuthGuard><FounderDetail /></AuthGuard>} />
              <Route path="/founder/add-template" element={<AuthGuard><AddFounderTemplate /></AuthGuard>} />
              <Route path="/founder/edit-template" element={<AuthGuard><EditFounderTemplate /></AuthGuard>} />
              <Route path="/founder/add-profile" element={<AuthGuard><AddProfile /></AuthGuard>} />
              <Route path="/founder/edit-profile" element={<AuthGuard><EditProfile /></AuthGuard>} />
              <Route path="/founder/add-post" element={<AuthGuard><AddFounderPost /></AuthGuard>} />
              <Route path="/founder/edit-post" element={<AuthGuard><EditFounderPost /></AuthGuard>} />
              <Route path="/posts" element={<AuthGuard><Posts /></AuthGuard>} />
              <Route path="/linkedin-profile" element={<AuthGuard><LinkedInProfile /></AuthGuard>} />
              <Route path="/linkedin-profile/add-profile" element={<AuthGuard><AddLinkedInProfile /></AuthGuard>} />
              <Route path="/linkedin-profile/edit-profile" element={<AuthGuard><EditLinkedInProfile /></AuthGuard>} />
              <Route path="/hot-topic/add-topic" element={<AuthGuard><AddTopic /></AuthGuard>} />
            </Routes>
          </Router>
          <ToastContainer />
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
