import { AppBar, Box, Breadcrumbs, Button, Container, Grid, Stack, styled, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AvatarUpload from '../../component/AvatarUpload';
import Header from '../../layout/Header';
import { editLinkedInProfile } from '../../services/linkedin.service';

const StyledGrid = styled(Grid)(({ theme }) => ({
    background: 'white',
    padding: 32,
    borderRadius: 32,
}));

const Label = ({ label, required }) => {
    return (
        <Typography variant='subtitle2' mb={0.5}>
            {label} {required && <span style={{ color: 'tomato' }}>*</span>}
        </Typography>
    )
}

const EditLinkedInProfile = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { mutate, isPending } = useMutation({
        mutationFn: editLinkedInProfile,
        onSuccess: () => {
            toast.success('Profile Edit successfully!');
            navigate('/linkedin-profile')
        },
        onError: (err) => {
            toast.error(err.message || 'Failed to edit profile');
        }
    })

    const [formData, setFormData] = useState({
        username: state?.username || '',
        fullName: state?.fullName || '',
        profileUrl: state?.profileUrl || '',
        description: state?.description || '',
        image: null
    });

    if (!state) {
        return <>404</>
    }

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('username', formData.username);
        formDataToSend.append('fullName', formData.fullName);
        formDataToSend.append('profileUrl', formData.profileUrl);
        formDataToSend.append('description', formData.description);

        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }

        mutate({ formData, profileId: state._id });
    };

    return (
        <>
            <Header />
            <Container >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4 }}>
                    <Link style={{ color: '#858585' }} to="/linkedin-profile">
                        Home
                    </Link>
                    <Typography color="text.primary">Edit LinkedIn Profile</Typography>
                </Breadcrumbs>
                <Box mt={3}>
                    <Typography variant="h6" fontWeight={600} color="textPrimary">
                       Edit LinkedIn Profile
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        Provide your details to edit a LinkedIn profile.
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <form onSubmit={handleSubmit}>
                        <StyledGrid container >
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    LinkedIn Profile
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    Please upload a profile photo for the LinkedIn profile.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* <Label label={'Profile Pic'} /> */}
                                <Stack justifyContent={'center'} alignItems={'center'} >
                                    <AvatarUpload  defaultImage={state?.image} name='image' onChange={handleChange} />
                                </Stack>
                            </Grid>
                        </StyledGrid>
                        <StyledGrid container mt={4}  >
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    Profile Details
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    All fields are required. Please provide the following information.

                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box mb={2}>
                                    <Label label={'User Name'} required={true} />
                                    <TextField required size='small' fullWidth name="username" variant="outlined" value={formData.username} onChange={handleChange} placeholder='Enter User Name' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Full Name'} required={true} />
                                    <TextField required fullWidth size='small' name="fullName" variant="outlined" value={formData.fullName} onChange={handleChange} placeholder='Enter Full Name' />
                                </Box>

                                <Box mb={2}>
                                    <Label label={'Profile URL'} required={true} />
                                    <TextField required fullWidth size='small' name="profileUrl" variant="outlined" value={formData.profileUrl} onChange={handleChange} type="url" placeholder='Enter Profile URL' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Description'} required={true} />
                                    <TextField required fullWidth size='small' name="description" variant="outlined" value={formData.description} onChange={handleChange} placeholder='Enter Description' />
                                </Box>
                            </Grid>
                        </StyledGrid>


                        <Box height={100} />
                        <AppBar position="fixed" color="default" sx={{ top: 'auto', py: 2.5, bgcolor: 'white', bottom: 0 }}>
                            <Container>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Link to="/linkedin-profile">
                                        <Button type="button" variant="outlined" color="primary">
                                            Cancel
                                        </Button>
                                    </Link>
                                    <Button type="submit" variant="contained" color="primary" disabled={isPending}>
                                        Submit
                                    </Button>
                                </Stack>
                            </Container>
                        </AppBar>
                    </form>
                </Box>
            </Container>
        </>
    );
};

export default EditLinkedInProfile;
