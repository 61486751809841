import api from "../utils/api";

export const generatePostIdea = async (body) => {
    try {
        const response = await api.post('/genai/generate-post', body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};


