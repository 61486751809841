import api from "../utils/api";

export const fetchFounderPost = async ({ page = 1, limit = 10, search = '', sortBy = 'DESC', founder_id }) => {
    try {
        const response = await api.get('/post', {
            params: { founder_id, page, limit, search, sortBy }
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};

export const addFounderPost = async ({ founder_id, body }) => {
    try {
        const response = await api.post(`/post/${founder_id}`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};
export const editFounderPost = async ({ post_id, body }) => {
    try {
        const response = await api.patch(`/post/${post_id}`, body);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Network response was not ok');
    }
};