import api from "../utils/api";

export const loginService = async ({ email, password }) => {
    try {
        const response = await api.post('/auth/login', { email, password });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Server Error');
    }
};
