import { Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { DotsThreeVertical, Eye, PencilSimple, Plus } from "phosphor-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "../../component/SearchBox";
import Header from "../../layout/Header";
import { fetchFounders } from "../../services/founder.service";
import { getLinkedInUsername } from "../../utils/helper";

const ActionMenu = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigator = useNavigate();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePreview = () => {
    navigator(`/founders/${row._id}`)
  }
  const handleEdit = () => {
    navigator(`/edit-founder/`, {
      state: row
    })
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <DotsThreeVertical />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handlePreview}>
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <Eye size={20} color="#79767E" />
            <Typography variant="body2" color={'text.light'} >Preview</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <Stack direction={'row'} alignItems={'center'} gap={2}>
            <PencilSimple size={20} color="#79767E" />
            <Typography variant="body2" color={'text.light'} >Edit</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </div>
  );
};

const columns = [
  { field: '_id', headerName: 'ID' },
  {
    field: 'full_name',
    headerName: 'Full name',
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <Stack direction={'row'} height={'100%'} alignItems={'center'} gap={1}>
          <Avatar src={row.image} alt="founder" />
          <Typography variant="body2">{row.full_name}</Typography>
        </Stack>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'phone_number',
    headerName: 'Phone',
    flex: 1,
    renderCell: ({ row }) => {
      return (
        `+${row.country_code}${row.phone_number}`
      )
    }
  },
  {
    field: 'linkedin_url',
    headerName: 'LinkedIn',
    flex: 1,
    renderCell: ({ row }) => {
      const username = getLinkedInUsername(row.linkedin_url);

      if (!username) {
        return <></>;
      }

      return (
        <Link style={{ color: 'black' }} onClick={(event) => event.stopPropagation()} target="_blank" to={row.linkedin_url}>
          {username}
        </Link>
      );
    }
  },
  {
    field: 'company',
    headerName: 'Company',
    flex: 1,
  },
  {
    headerName: 'Action',
    width: 120,
    renderCell: ({ row }) => <ActionMenu row={row} />,

  },
];


const Founder = () => {
  const [search, setSearch] = useState('');
  const navigator = useNavigate();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0
  })

  const query = {
    page: pagination.page + 1,
    limit: pagination.pageSize,
    search
  }

  const { data, isLoading, } = useQuery({
    queryKey: ['founders', query],
    queryFn: ({ queryKey }) => fetchFounders(queryKey[1])
  });

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  return (
    <>
      <Header />
      <Container maxWidth='lg' sx={{ pb: '100px' }}>
        <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} mt={4} mb={6}>
          <Box>
            <Typography variant="h4" color={'text.main'} fontWeight={600}>Founder List</Typography>
            <Typography variant="body1" color={'text.light'}>Founder Directory stores the profiles of our company's founders. <br /> Here you can view, manage, and update founder information
            </Typography>
          </Box>
          <Link to={'/add-founder'}>
            <Button variant="contained" size="large" endIcon={<Plus />}>Add New Founder</Button>
          </Link>
        </Stack>
        <Stack mb={4}>
          <SearchBox onSearchChange={handleSearchChange} isLoading={isLoading} />
        </Stack>
        <Box sx={{ height: '100vh', width: '100%' }}>
          <DataGrid
            rowHeight={60}
            rows={data?.founders || []}
            columns={columns}
            onRowClick={(row) => {
              navigator(`/founders/${row.id}`)
            }}
            disableRowSelectionOnClick
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  _id: false
                }
              }
            }}
            loading={isLoading}
            paginationMode="server"
            rowCount={data?.total || 0}
            pageSizeOptions={[10, 25, 50]}
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
            getRowId={(row) => row._id}
            pagination

          />
        </Box>
      </Container>
    </>
  )
}

export default Founder;
