import { Avatar, Box, CircularProgress, IconButton, Stack, styled, Typography } from "@mui/material";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CopySimple } from "phosphor-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchMetaData } from "../services/scrapper.service";

const StyledBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    background: 'white',
    borderRadius: 8,
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    width: '400px',
    padding: 12,
    bottom: '28px',
    '& .preview-body': {
        height: '200px',
        borderRadius: 8,
        overflow: 'hidden',
        'img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    }
}));
const URLPreviewer = ({ url, children }) => {
    const queryClient = useQueryClient();
    const [showData, setShowData] = useState(false);

    const query = useMemo(() => {
        return { url }
    }, [url])

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['meta-data', query],
        queryFn: ({ queryKey }) => fetchMetaData(queryKey[1]),
        enabled: false,
    });

    const handleMouseEnter = useCallback(() => {
        setShowData(true);
        refetch();
    }, [refetch]);

    const handleMouseLeave = useCallback(() => {
        setShowData(false);
        queryClient.cancelQueries(['meta-data', query]);
    }, [queryClient, query]);

    useEffect(() => {
        return () => {
            queryClient.cancelQueries(['meta-data', query]);
        };
    }, [queryClient, query]);

    const handleCopy = () => {
        navigator.clipboard.writeText(url)
    }
    return (
        <Box
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{ position: 'relative', py: 1 }}>

            {showData && (
                <StyledBox >
                    {isLoading ? (
                        <Stack alignItems={'center'} justifyContent={'center'}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <>

                            <Stack className="preview-header" flexDirection={'row'} alignItems={'center'} gap={2}>
                                <Avatar variant="rounded" sx={{ height: '30px', width: '30px' }} src={data?.favicon} />
                                <Typography noWrap color={'primary.dark'} fontWeight={600} variant="body2">{data?.title}</Typography>
                                <IconButton onClick={handleCopy}>
                                    <CopySimple size={20} />
                                </IconButton>
                            </Stack>
                            <Box className="preview-body" my={1}>
                                <img src={data?.image} alt="profile" />
                            </Box>
                            <Box className="preview-description" >
                                <Typography variant="body2"   >
                                    {data?.description?.length > 300 ? data?.description?.slice(0, 300) + '...' : data?.description}
                                </Typography>
                            </Box>
                        </>
                    )}
                </StyledBox>
            )}
            <div
            >
                {children}
            </div>
        </Box>
    );
};

export default URLPreviewer;
