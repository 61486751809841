import { Box, Container, Tab, Tabs } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/Header";
import FounderPost from "./founder-post";
import ReleventProfiles from "./relevent-profile";
import TemplateIdeas from "./template-idea";
import ReleventNews from "./relevent-news";

const FounderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("tab");
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = useMemo(
    () => [
      {
        name: 'Template Ideas',
        path: 'template-idea',
        component: <TemplateIdeas />
      },
      {
        name: 'Relevant Profile',
        path: 'relevent-profile',
        component: <ReleventProfiles />
      },
      {
        name: 'Relevant News',
        path: 'relevant-news',
        component: <ReleventNews />
      },
      {
        name: 'Past LinkedIn Posts',
        path: 'past-post',
        component: <FounderPost />
      }
    ],
    []
  );

  useEffect(() => {
    if (tabParam) {
      const tabIndex = tabs.findIndex(
        (tab) => tab.path.toLowerCase() === tabParam.toLowerCase()
      );
      if (tabIndex !== -1) {
        setCurrentTab(tabIndex);
      }
    }
  }, [tabs, tabParam]);

  const handleTabChange = (_, newValue) => {
    setCurrentTab(newValue);
    const newParams = new URLSearchParams(location.search);
    newParams.set("tab", tabs[newValue].path);
    navigate(`?${newParams.toString()}`);
  }

  return (
    <>
      <Header />
      <Container sx={{ mt: 2 }}>
        <Box>
          <Tabs onChange={handleTabChange} value={currentTab}>
            {
              tabs.map((tab, index) => (
                <Tab key={tab.name} label={tab.name} value={index} />
              ))
            }
          </Tabs>
        </Box>
        <Box mt={4}>
          {tabs[currentTab].component}
        </Box>
      </Container>
    </>
  );
}

export default FounderDetail;
