import { Box, Button, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../asset/svg/logo';
import { loginService } from '../../services/auth.service';
import { Eye, EyeSlash } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice/auth.slice';

const Login = () => {
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const dispatch=useDispatch();

    const navigator= useNavigate();
    const mutation = useMutation({
        mutationFn: loginService,
        onSuccess: (data) => {
            dispatch(login(data))
            toast.success('Login successful!');
            navigator('/')
        },
        onError: (error) => {
            toast.error(`Login failed: ${error.message}`);
        },
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
    };

    const handleLogin = () => {
        mutation.mutate(credentials);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <Stack alignItems={'center'} justifyContent={'center'} height={'100vh'} bgcolor={'primary.lighter'}>
            <Paper elevation={3} sx={{ paddingX: 4, paddingY: 6, width: '450px' }}>
                <Box>
                    <Stack alignItems={'center'} justifyContent={'center'} pb={4}>
                        <Logo />
                    </Stack>
                    <Typography variant="h5" fontWeight={600} gutterBottom color="text.primary">
                        Login to Your Account
                    </Typography>
                    <Typography variant="body1" mb={2} gutterBottom color="text.secondary">
                        Please enter your credentials to continue.
                    </Typography>
                    <Typography variant='subtitle2' mb={0.5}>Email</Typography>
                    <TextField
                        type="text"
                        size='small'
                        name="email"
                        variant="outlined"
                        fullWidth
                        value={credentials.email}
                        onChange={handleChange}
                    />
                    <Typography variant='subtitle2' mt={2} mb={0.5}>Password</Typography>
                    <TextField
                        type={showPassword ? "text" : "password"}
                        name="password"
                        size='small'
                        variant="outlined"
                        fullWidth
                        value={credentials.password}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={togglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? <EyeSlash size={24} /> : <Eye size={24} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        sx={{ mt: 2, py: 1.5 }}
                        onClick={handleLogin}
                        disabled={mutation.isLoading}
                    >
                        <Typography variant="body1" fontWeight={600}>
                            {mutation.isLoading ? 'Logging in...' : 'LOGIN'}
                        </Typography>
                    </Button>
                </Box>
            </Paper>
        </Stack>
    );
};

export default Login;
