import { AppBar, Box, Breadcrumbs, Button, Container, Grid, MenuItem, Select, Stack, styled, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import countryCode from '../../asset/svg/countryCode';
import AvatarUpload from '../../component/AvatarUpload';
import Header from '../../layout/Header';
import { editFounder } from '../../services/founder.service';

const StyledGrid = styled(Grid)(({ theme }) => ({
    background: 'white',
    padding: 32,
    borderRadius: 32,
}));

const Label = ({ label, required }) => {
    return (
        <Typography variant='subtitle2' mb={0.5}>
            {label} {required && <span style={{ color: 'tomato' }}>*</span>}
        </Typography>
    )
}

const EditFounder = () => {

    const navigate = useNavigate();
    const { state } = useLocation();
    const { mutate, isPending } = useMutation({
        mutationFn: editFounder,
        onSuccess: () => {
            toast.success('Founder Details Edit Successfully!');
            navigate('/');
        },
        onError: (err) => {
            toast.error(err.message || 'Failed to edit founder details');
        }
    })

    const [formData, setFormData] = useState({
        full_name: state?.full_name || '',
        email: state?.email || '',
        phone_number: state?.phone_number || '',
        country_code: state?.country_code || '91',
        linkedin_url: state?.linkedin_url || '',
        company: state?.company || '',
        keywords:state?.keywords?.join(',') || '',
        image: null,
    });

    if (!state) {
        return <>404</>
    }
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('full_name', formData.full_name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('phone_number', formData.phone_number);
        formDataToSend.append('country_code', formData.country_code);
        formDataToSend.append('linkedin_url', formData.linkedin_url);
        formDataToSend.append('company', formData.company);
        formDataToSend.append('keywords', formData.keywords);

        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }

        mutate({ formData, founderId: state._id });
    };

    return (
        <>
            <Header />
            <Container >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mt: 4 }}>
                    <Link style={{ color: '#858585' }} to="/">
                        Home
                    </Link>
                    <Typography color="text.primary">Edit Founder</Typography>
                </Breadcrumbs>
                <Box mt={3}>
                    <Typography variant="h6" fontWeight={600} color="textPrimary">
                        Edit Founder
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        Provide Your Details to edit.
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <form onSubmit={handleSubmit}>
                        <StyledGrid container >
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    Founder Profile
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    Please upload a profile photo for the founder.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* <Label label={'Profile Pic'} /> */}
                                <Stack justifyContent={'center'} alignItems={'center'} >
                                    <AvatarUpload defaultImage={state?.image} name='image' onChange={handleChange} />
                                </Stack>
                            </Grid>
                        </StyledGrid>
                        <StyledGrid container mt={4}  >
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    Founder Details
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    All fields are required. Please provide the following information.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box mb={2}>
                                    <Label label={'Full Name'} required={true} />
                                    <TextField required size='small' fullWidth name="full_name" variant="outlined" value={formData.full_name} onChange={handleChange} placeholder='Enter Full Name' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Email'} required={true} />
                                    <TextField required fullWidth size='small' name="email" variant="outlined" value={formData.email} onChange={handleChange} type="email" placeholder='Enter Email' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Phone Number'} required={true} />
                                    <Stack direction={'row'} gap={1}>
                                        <Select name='country_code' size='small' value={formData.country_code} onChange={handleChange} MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 300,
                                                },
                                            },
                                        }}>
                                            {countryCode.map((countryItem) => (
                                                <MenuItem key={countryItem.value} value={countryItem.value}>+{countryItem.label}</MenuItem>
                                            ))}
                                        </Select>

                                        <TextField required fullWidth size='small' name="phone_number" variant="outlined" value={formData.phone_number} onChange={handleChange} type="tel" placeholder='Enter Phone Number' />
                                    </Stack>
                                </Box>
                                <Box mb={2}>
                                    <Label label={'LinkedIn URL'} required={true} />
                                    <TextField required fullWidth size='small' name="linkedin_url" variant="outlined" value={formData.linkedin_url} onChange={handleChange} type="url" placeholder='Enter LinkedIn URL' />
                                </Box>
                                <Box mb={2}>
                                    <Label label={'Company'} required={true} />
                                    <TextField required fullWidth size='small' name="company" variant="outlined" value={formData.company} onChange={handleChange} placeholder='Enter Company' />
                                </Box>
                            </Grid>
                        </StyledGrid>

                        <StyledGrid container mt={4}  >
                            <Grid item xs={12} sm={6}>
                                <Typography variant='body1' fontWeight={600}>
                                    Keywords
                                </Typography>
                                <Typography variant='body2' color={'text.light'}>
                                    All fields are required. Please provide the following information.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box mb={2}>
                                    <Label label={'Keywords'} required={true} />
                                    <TextField required fullWidth size='small' name="keywords" variant="outlined" value={formData.keywords} onChange={handleChange} placeholder='Enter Keywords seperated by (,)' />
                                </Box>
                            </Grid>
                        </StyledGrid>

                        <Box height={100} />
                        <AppBar position="fixed" color="default" sx={{ top: 'auto', py: 2.5, bgcolor: 'white', bottom: 0 }}>
                            <Container>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Link to="/">
                                        <Button type="button" variant="outlined" color="primary">
                                            Cancel
                                        </Button>
                                    </Link>
                                    <Button type="submit" variant="contained" color="primary" disabled={isPending}>
                                        Submit
                                    </Button>
                                </Stack>
                            </Container>
                        </AppBar>
                    </form>
                </Box>
            </Container>
        </>
    );
};

export default EditFounder;
