import CelebrateIcon from '../asset/svg/CelebrateIcon';
import FunnyIcon from '../asset/svg/FunnyIcon';
import InsightIcon from '../asset/svg/InsightIcon';
import LikeIcon from '../asset/svg/LikeIcon';
import LoveIcon from '../asset/svg/LoveIcon';
import SupportIcon from '../asset/svg/SupportIcon';

const REACTION_MAP = {
    LIKE: <LikeIcon />,
    APPRECIATION: <SupportIcon />,
    EMPATHY: <LoveIcon />,
    ENTERTAINMENT: <FunnyIcon />,
    PRAISE: <CelebrateIcon />,
    INTEREST: <InsightIcon />
}

export default REACTION_MAP;